import React, { Component } from "react"
import { Checkbox, Table } from "antd"
import { getCompanies } from "../services/companyService"

class DepartmentsCheckboxTable extends Component {
  state = {
    loading: false,
    companies: []
  }

  async componentDidMount() {
    this.setState({ loading: true })

    const { data: companies } = await getCompanies()

    this.setState({
      loading: false,
      companies
    })
  }

  render() {
    const { loading, companies } = this.state
    const { companyLeaderIds, companyIds } = this.props

    const columns = [
      {
        title: "Afdelinger",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Leder",
        // dataIndex: "leader",
        key: "leader",
        width: "20%",
        render: record => {
          return (
            <Checkbox
              checked={companyLeaderIds.includes(record.id)}
              // style={{marginLeft: record.children ? 0 : 15}}
              onChange={() => this.props.handleLeaderCheckbox(record.id)}
            />
          )
        }
      },
      {
        title: "Arbejdssted",
        // dataIndex: "placed",
        key: "placed",
        width: "20%",
        render: record => {
          return (
            <Checkbox
              checked={companyIds.includes(record.id)}
              onChange={() => this.props.handleCompanyCheckbox(record.id)}
            />
          )
        }
      }
    ]

    return (
      <Table
        loading={loading}
        columns={columns}
        dataSource={companies}
        rowKey="id"
        className="table-card"
        bordered
      />
    )
  }
}
export default DepartmentsCheckboxTable

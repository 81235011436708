import React, { Component } from "react"
import { Form, message, Row, Select, Col, Card, Spin, Input } from "antd"
import { saveParticipant, getParticipant } from "../services/participantService"
import { getParticipantCompanies } from "../services/participantCompaniesService"
import FormActions from "../components/common/formActions"
import PageTitle from "../components/common/PageTitle"

const { Option } = Select

class ParticipantForm extends Component {
  state = {
    loading: false,
    participantCompanies: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: participantCompanies } = await getParticipantCompanies()
    this.populateForm(this.props.match.params.id)
    this.setState({
      loading: false,
      participantCompanies
    })
  }

  populateForm = async id => {
    if (id === "new") return
    try {
      const { data: participant } = await getParticipant(id)
      if (participant)
        this.props.form.setFieldsValue({
          id: participant.id,
          name: participant.name,
          // phone: participant.phone,
          mobile: participant.mobile,
          email: participant.email,
          companyId: participant.company ? participant.company.id : undefined
        })
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found")
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ loading: true })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        global.log("Received values of form: ", values)
        await saveParticipant(values)
        this.setState({ loading: false })
        message.success("Kontaktperson gemt", 3)
        this.props.history.push("/participants")
      } else {
        this.setState({ loading: false })
      }
    })
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { loading, participantCompanies } = this.state
    const formItemLayout = { colon: false }
    const participantName = getFieldValue("name")

    getFieldDecorator("id", { initialValue: 0 })

    return (
      <>
        <PageTitle title={this.props.title} />
        <Card
          title={
            !participantName || participantName === ""
              ? "Ny kontaktperson"
              : participantName
          }
          className="card"
          bordered={false}
          actions={[
            <FormActions
              key={1}
              onSubmit={this.handleSubmit}
              cancelLink="/participants"
            />
          ]}
        >
          {loading ? (
            <div className="spin-card-overlay">
              <Spin />
            </div>
          ) : null}
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={15}>
              <Col md={{ span: 12 }}>
                <Form.Item {...formItemLayout} label="Navn">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Udfyld venligst feltet"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={{ span: 10 }}>
                <Form.Item {...formItemLayout} label="Firma">
                  {getFieldDecorator("companyId", {
                    rules: [
                      {
                        required: true,
                        message: "Vælg venligst et firma"
                      }
                    ]
                  })(
                    <Select placeholder="Vælg firma" style={{ width: "100%" }}>
                      {participantCompanies.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={24}>
                <div className="form-group-title">Kontakt info</div>
              </Col>
              {/*               <Col md={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Telefon">
                  {getFieldDecorator("phone")(<Input />)}
                </Form.Item>
              </Col> */}
              <Col md={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Mobil">
                  {getFieldDecorator("mobile")(<Input />)}
                </Form.Item>
              </Col>
              <Col md={{ span: 8 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "Udfyld venligst feltet med Email"
                      },
                      {
                        required: true,
                        message: "Udfyld venligst feltet"
                      }
                    ]
                  })(<Input placeholder="Email" />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    )
  }
}
export default Form.create()(ParticipantForm)

import React, { Component } from "react"
import { Form } from "antd"
import FormActions from "./common/formActions"
// import {saveLocation} from "../services/locationService"
import LocationItem from "./formFields/locationItem"
import ProviderSelect from "./providerSelect"

class LocationFormDrawer extends Component {
  componentDidMount() {
    global.log(this.props.providerId)
  }

  handleSubmit = () => {
    const { form, onAdded } = this.props
    const { validateFields } = form

    validateFields(async (err, values) => {
      if (!err) {
        try {
          // const {data} = await saveLocation(values)
          const rId = Math.floor(Math.random() * (900 - 0)) + 0
          const newValues = values
          newValues.id = rId
          onAdded(newValues)
          global.log("save to: api/location/", newValues)
        } catch (error) {
          global.log(error.response)
        }
      }
    })
  }

  render() {
    const { form, onCancel, providerId } = this.props
    const { getFieldDecorator } = form

    return (
      <>
        <h2 className="drawer-title">Tilføj Lokation</h2>
        <Form colon={false}>
          <Form.Item>
            <ProviderSelect
              disabled
              disableAdd
              initial={providerId}
              form={form}
            />
          </Form.Item>
          <LocationItem getFieldDecorator={getFieldDecorator} />
        </Form>
        <div className="drawer-footer">
          <FormActions onSubmit={this.handleSubmit} onCancel={onCancel} />
        </div>
      </>
    )
  }
}

export default Form.create()(LocationFormDrawer)

import React from "react"
import { Result, Icon } from "antd"
import PageTitle from "../components/common/PageTitle"

const NotFound = ({ title }) => {
  return (
    <>
      <PageTitle title={title} />
      <div className="empty-page">
        <Result
          icon={<Icon type="api" theme="twoTone" />}
          title="Du har forsøgt at tilgå en side, som ikke findes."
          extra={
            <p>
              Det kan enten være fordi, du har indtastet en forkert webadresse,
              eller fordi den side, du blev linket til, ikke længere findes.
            </p>
          }
        />
      </div>
    </>
  )
}

export default NotFound

import React from "react"
import { Card, Row, Select, Col, Icon, Input, Button } from "antd"

const { Option } = Select

const Responsables = ({ form, data, disabled }) => {
  const { getFieldValue, getFieldDecorator } = form
  const init = getFieldValue("initResponsables")
  const initital = Array.isArray(init) || init.length ? init : []
  const initKeys = Object.keys(initital).map(Number)

  getFieldDecorator("responsableKeys", {
    initialValue: initKeys.length > 0 ? initKeys : []
  })

  const responsableKeys = getFieldValue("responsableKeys")

  const responsibleItems = responsableKeys.map(k => (
    <Row
      type="flex"
      align="middle"
      key={`${k}wrapper`}
      className="repeated-fileds-row"
      gutter={15}
    >
      <Col md={{ span: 11 }} xs={{ span: 24 }}>
        {getFieldDecorator(`responsables[${k}].id`, {
          initialValue: initital[k] && initital[k].id
        })(
          <Select
            disabled={disabled}
            showSearch
            onSearch={() => handleEmployeeSearch()}
            placeholder="Vælg medarbejder.."
            optionFilterProp="children"
            onBlur={() => clearEmployeeSearch}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {data.map(d => (
              <Option
                value={d.id}
                key={d.id}
                disabled={initital.some(item => item.id === d.id)}
              >
                {d.name}
              </Option>
            ))}
          </Select>
        )}
      </Col>
      <Col className="col-grow">
        {getFieldDecorator(`responsables[${k}].responsibility`, {
          initialValue: initital[k] && initital[k].responsibility
        })(<Input disabled={disabled} placeholder="Ansvarområde" />)}
      </Col>
      <Col className="delete-repeated-btn">
        <Icon
          className="dynamic-delete-button"
          type="minus-circle-o"
          disabled={responsableKeys.length === 1}
          onClick={() => removeResponable(k, form)}
        />
      </Col>
    </Row>
  ))

  return (
    <Card
      type="inner"
      title="Øvrige ansvarlige"
      className="styled-inner-card responsable-card"
    >
      {responsibleItems}
      <Button
        disabled={disabled}
        type="dashed"
        onClick={() => addResponsable(form)}
      >
        <Icon type="plus" /> Tilføj ansvarlig
      </Button>
    </Card>
  )
}
const addResponsable = form => {
  const responsableKeys = form.getFieldValue("responsableKeys")
  let newKey = responsableKeys.length
  while (responsableKeys.includes(newKey)) {
    newKey++
  }
  const nextResponsableKeys = responsableKeys.concat(newKey)
  form.setFieldsValue({ responsableKeys: nextResponsableKeys })
  global.log(form.getFieldValue("responsableKeys"))
}
const handleEmployeeSearch = value => {
  global.log("Search will be activated later.", value)
  // this.setState({employeeSearchValue: value})
}
const clearEmployeeSearch = () => {
  global.log("Search cleared")
  // this.setState({employeeSearchValue: ""})
}
const removeResponable = (k, form) => {
  const responsableKeys = form.getFieldValue("responsableKeys")
  form.setFieldsValue({
    responsableKeys: responsableKeys.filter(key => key !== k)
  })

  const initResponsables = form.getFieldValue("initResponsables")

  if (initResponsables[k])
    form.setFieldsValue({
      initResponsables: initResponsables.filter((item, i) => i !== k)
    })
}

export default Responsables

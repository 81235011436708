import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/TicketApproval`

function ticketApprovalUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getTicketApprovals() {
  return http.get(`${apiEndpoint}/List`)
}

export function getTicketApproval(ticketApprovalId) {
  return http.get(ticketApprovalUrl(ticketApprovalId))
}

export function saveTicketApproval(ticketApproval) {
  if (ticketApproval.id !== 0) return http.put(apiEndpoint, ticketApproval)

  return http.post(apiEndpoint, ticketApproval)
}

export function deleteTicketApproval(ticketApprovalId) {
  return http.delete(ticketApprovalUrl(ticketApprovalId))
}

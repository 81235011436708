import React from "react"
import { Redirect, Switch } from "react-router-dom"
import { flatRoutes } from "./appRoutes"

const Routes = () => {
  return (
    <Switch>
      {flatRoutes.map(route => (
        <route.route
          key={route.path}
          path={route.path}
          render={props => (
            <route.component
              {...props}
              title={route.title}
              {...route.componentParams}
            />
          )}
        />
      ))}
      <Redirect from="/" exact to="/dashboard" />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes

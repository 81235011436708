import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/Employee`

function employeeUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getEmployees() {
  return http.get(`${apiEndpoint}/List`)
}

export function getEmployee(employeeId) {
  return http.get(employeeUrl(employeeId))
}

export function saveEmployee(employee) {
  if (employee.id !== 0) return http.put(apiEndpoint, employee)

  return http.post(apiEndpoint, employee)
}

export function deleteEmployee(employeeId) {
  return http.delete(employeeUrl(employeeId))
}

import React, { Component } from "react"
import { Form, message, Card, Spin } from "antd"
import { saveProvider, getProvider } from "../services/providerService"
import ProviderFormFields from "../components/providerFormFields"
import PageTitle from "../components/common/PageTitle"
import FormActions from "../components/common/formActions"

class ProviderForm extends Component {
  state = { loading: false }

  componentDidMount() {
    this.populateForm()
  }

  populateForm = async () => {
    const { id } = this.props.match.params
    if (id === "new") return
    try {
      const { data } = await getProvider(id)
      global.log("Server response:", data)
      const provider = { ...data }
      const initialLocations = provider.locations
      const initialContacts = provider.contacts
      delete provider.contacts
      delete provider.locations
      provider.initialLocations = initialLocations
      provider.initialContacts = initialContacts
      this.props.form.setFieldsValue(provider)
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found")
    }
  }

  handleSubmit = async () => {
    this.setState({ loading: true })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        global.log("Form values:", values)
        await saveProvider(values)
        this.setState({ loading: false })
        message.success("Udbyder er gemt", 3)
        this.props.history.push("/providers")
      } else {
        this.setState({ loading: false })
      }
    })
  }

  render() {
    const { form } = this.props
    const { loading } = this.state
    const providerName = form.getFieldValue("name")

    return (
      <>
        <PageTitle title={this.props.title} />
        <Card
          title={
            !providerName || providerName === ""
              ? "Ny medarbejder"
              : providerName
          }
          className="card"
          bordered={false}
          actions={[
            <FormActions
              key={1}
              onSubmit={this.handleSubmit}
              cancelLink="/providers"
            />
          ]}
        >
          {loading ? (
            <div className="spin-card-overlay">
              <Spin />
            </div>
          ) : null}
          <Form colon={false}>
            <ProviderFormFields form={form} />
          </Form>
        </Card>
      </>
    )
  }
}

export default Form.create()(ProviderForm)

import React, { createContext, useContext, useReducer } from "react"

const DrawerStateContext = createContext()
const DrawerDispatchContext = createContext()

const initState = {
  id: undefined,
  type: "",
  field: "",
  drawerVisible: false,
  addedItem: {}
}

function drawerReducer(state, action) {
  switch (action.type) {
    case "ADD_PROVIDER":
      return {
        id: action.providerId,
        field: action.field,
        type: "provider",
        drawerVisible: true,
        addedItem: {}
      }

    case "ADD_LOCATION":
      return {
        id: action.providerId,
        field: action.field,
        type: "location",
        drawerVisible: true,
        addedItem: {}
      }

    case "ADD_CONTACT":
      return {
        id: action.providerId,
        field: action.field,
        type: "contact",
        drawerVisible: true,
        addedItem: {}
      }

    case "DONE":
      return {
        ...state,
        drawerVisible: false,
        addedItem: action.payload
      }

    case "CANCEL":
    case "RESET":
      return initState

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

function DrawerProvider({ children }) {
  const [state, dispatch] = useReducer(drawerReducer, initState)
  return (
    <DrawerStateContext.Provider value={state}>
      <DrawerDispatchContext.Provider value={dispatch}>
        {children}
      </DrawerDispatchContext.Provider>
    </DrawerStateContext.Provider>
  )
}

function useDrawerState() {
  const context = useContext(DrawerStateContext)
  if (context === undefined) {
    throw new Error("useDrawerState must be used within a DrawerProvider")
  }
  return context
}

function useDrawerDispatch() {
  const context = useContext(DrawerDispatchContext)
  if (context === undefined) {
    throw new Error("useDrawerDispatch must be used within a DrawerProvider")
  }
  return context
}

export { DrawerProvider, useDrawerState, useDrawerDispatch }

import React from "react"
import { Form, Input, Icon } from "antd"
import { PropTypes } from "prop-types"

const ExtContactItem = ({
  getFieldDecorator,
  initial,
  parentName,
  autoComplete
}) => {
  const pn = parentName && parentName.length ? `${parentName}.` : ""

  return (
    <>
      {getFieldDecorator(`${pn}id`, { initialValue: initial ? initial.id : 0 })(
        <Input type="hidden" />
      )}
      <Form.Item>
        {getFieldDecorator(`${pn}name`, {
          initialValue: initial && initial.name,
          rules: [
            {
              required: true,
              message: "Påkravet Feltet"
            }
          ]
        })(
          <Input
            autoFocus
            placeholder="Navn"
            autoComplete={!autoComplete ? "new-pass" : ""}
          />
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator(`${pn}title`, {
          initialValue: initial && initial.title
        })(
          <Input
            placeholder="Stilling"
            autoComplete={!autoComplete ? "new-pass" : ""}
          />
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator(`${pn}email`, {
          initialValue: initial && initial.email
        })(
          <Input
            prefix={<Icon type="mail" />}
            placeholder="E-mail"
            autoComplete={!autoComplete ? "new-pass" : ""}
          />
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator(`${pn}mobile`, {
          initialValue: initial && initial.mobile
        })(
          <Input
            prefix={<Icon type="mobile" />}
            placeholder="Mobil"
            autoComplete={!autoComplete ? "new-pass" : ""}
          />
        )}
      </Form.Item>
    </>
  )
}
ExtContactItem.defaultProps = {
  initial: undefined,
  parentName: "",
  autoComplete: false,
  getFieldValue: () => {},
  setFieldsValue: () => {},
  getFieldDecorator: () => {}
}
ExtContactItem.propsType = {
  initial: PropTypes.object,
  parentName: PropTypes.string,
  autoComplete: PropTypes.bool,
  getFieldValue: PropTypes.func,
  setFieldsValue: PropTypes.func,
  getFieldDecorator: PropTypes.func.isRequired
}

export default ExtContactItem

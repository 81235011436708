import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Typography,
  Table,
  Popconfirm,
  Tag,
  Input,
  Button,
  Icon,
  message
} from "antd"
import Highlighter from "react-highlight-words"
import { deleteEmployee, getEmployees } from "../services/employeeService"
import PageTitle from "../components/common/PageTitle"

const { Text } = Typography
class Employees extends Component {
  state = {
    searchText: "",
    loading: false,
    employees: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: employees } = await getEmployees()
    this.setState({
      employees,
      loading: false
    })
  }

  handleDelete = async employee => {
    const originalEmployees = [...this.state.employees]
    const { id } = employee
    const employees = originalEmployees.filter(e => e.id !== employee.id)
    this.setState({
      loading: true,
      employees
    })

    try {
      await deleteEmployee(id)
      this.setState({ loading: false })
      message.success("Deleted")
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        message.error("Not deleted")

      this.setState({
        employees: originalEmployees,
        loading: false
      })
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    }
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: "" })
  }

  render() {
    const { searchText } = this.state
    const columns = [
      {
        title: "Nr.",
        dataIndex: "number",
        key: "number",
        width: 75
      },
      {
        title: "Navn",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name"),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
        render: (text, index) => (
          <Link to={`/employees/${index.id}`}>
            {searchText ? (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            ) : (
              text
            )}
          </Link>
        )
      },
      {
        title: "Titel",
        dataIndex: "title",
        key: "title"
      },
      {
        title: "Afdelinger",
        dataIndex: "companies",
        key: "companies",
        render: companies =>
          companies.map(company => (
            <Tag key={company.id} color={company.isLeader ? "red" : "blue"}>
              {company.name}
            </Tag>
          ))
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Telefon nr.",
        dataIndex: "phone",
        key: "phone",
        width: 140
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        width: 75,
        className: "action-column",
        render: record => (
          <Popconfirm
            title={`Er du sikker på at du vil slette ${record.name} ?`}
            okText="Ja"
            cancelText="Nej"
            onConfirm={() => this.handleDelete(record)}
          >
            <div className="delete-icon">
              <Icon type="delete" />
            </div>
          </Popconfirm>
        )
      }
    ]

    return (
      <>
        <PageTitle
          title={this.props.title}
          exstra={
            <Button type="primary">
              <Link to="/employees/new">Ny medarbejder</Link>
            </Button>
          }
        />
        <Table
          loading={this.state.loading}
          rowKey="id"
          columns={columns}
          dataSource={this.state.employees}
          onChange={this.onChange}
          className="table-card"
          footer={() => (
            <span>
              <Text type="secondary">Farver på afdelinger: </Text>
              <Tag color="red">Leder</Tag>
              <Tag color="blue">Medarbjeder</Tag>
            </span>
          )}
        />
      </>
    )
  }
}

export default Employees

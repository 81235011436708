import React from "react"
import { Link } from "react-router-dom"
import { Table, message, Icon, Popconfirm, Button } from "antd"
import _ from "lodash"
import { getCompanies, deleteCompany } from "../services/companyService"
import PageTitle from "../components/common/PageTitle"

class Departments extends React.Component {
  state = {
    companies: [],
    loading: false
  }
  /* onChange = (pagination, filters, sorter) => {
    global.log("params", pagination, filters, sorter)
  }; */

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: companies } = await getCompanies()
    this.setState({
      companies,
      loading: false
    })
  }

  handleDelete = async record => {
    const originalCompanies = [...this.state.companies]
    const { id } = record
    const companies = _.cloneDeepWith(originalCompanies, v =>
      _.find(v, { id }) ? _.reject(v, { id }) : undefined
    )
    this.setState({
      loading: true,
      companies
    })

    try {
      await deleteCompany(id)
      this.setState({ loading: false })
      message.success("Deleted")
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        message.error("Not deleted")

      this.setState({
        companies: originalCompanies,
        loading: false
      })
    }
  }

  render() {
    const columns = [
      {
        title: "Navn",
        dataIndex: "name",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
        render: (text, record) => (
          <Link to={`/departments/${record.id}`}>{text}</Link>
        )
      },
      {
        title: "Afd. nr.",
        dataIndex: "departmentNumber"
      },
      /* {
        title: "Ledere",
        dataIndex: "ledere",
        render: ledere =>
          ledere
            .map(leder => <span key={leder.key}>{leder.name}</span>)
            .reduce((prev, curr) => [prev, ", ", curr]),
      }, */
      {
        title: "Grupper",
        dataIndex: "groups",
        render: groups =>
          groups.map((group, i) => (
            <span key={group.id}>
              {group.name}
              {groups[i + 1] ? ", " : ""}
            </span>
          ))
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        width: 100,
        className: "action-column",
        render: record => (
          <Popconfirm
            title={`Er du sikker på at du vil slette ${record.name} ?`}
            okText="Ja"
            cancelText="Nej"
            onConfirm={() => this.handleDelete(record)}
          >
            <div className="delete-icon">
              <Icon type="delete" />
            </div>
          </Popconfirm>
        )
      }
    ]

    return (
      <>
        <PageTitle
          title={this.props.title}
          exstra={
            <Button type="primary">
              <Link to="/departments/new">Ny Afdeling</Link>
            </Button>
          }
        />
        <Table
          loading={this.state.loading}
          columns={columns}
          dataSource={this.state.companies}
          rowKey="id"
          onChange={this.onChange}
          className="table-card"
        />
      </>
    )
  }
}

export default Departments

import React, { useEffect, useState, useRef } from "react"
import { Button, Empty, Row, Avatar, Col, Input, Icon } from "antd"
import { stringToColor } from "../utils/stringToColor"
import { getInitials } from "../utils/getUserInitials"
import { formatDate, formatTime } from "../utils/dateFunc"
import { useUser } from "../contexts/userContext"
import { sendMessage } from "../services/ordersService"

const { TextArea } = Input

const ChatWindow = ({ initialMessages, employee, orderId, exstra }) => {
  const user = useUser()

  const chatBoard = useRef(null)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState(false)

  useEffect(() => {
    scrollToBottom()
    global.log(messages)
  }, [messages])

  useEffect(() => {
    scrollToBottom()
  }, [])

  const scrollToBottom = () => {
    if (chatBoard.current)
      chatBoard.current.scrollTop = chatBoard.current.scrollHeight
  }

  const handleSubmit = async () => {
    setMessages(messages =>
      messages.concat({
        id: "new",
        userName: user.given_name,
        orderId,
        isSent: false,
        rule: "admin",
        dateString: "lige nu",
        date: "2019-12-10T02:13:49.27",
        message
      })
    )
    setLoading(true)
    try {
      const { data } = await sendMessage({
        orderId,
        rule: "admin",
        message
      })
      setMessages(messages => messages.filter(m => m.id !== "new").concat(data))
      global.log(data)
    } catch (error) {
      setMessages(messages => messages.filter(m => m.id !== "new"))
      global.log(error)
    }
    setLoading(false)
  }
  return (
    <div className="chat-window">
      {employee && (
        <Row className="chat-head" type="flex" align="middle">
          <Col>
            <Avatar style={{ backgroundColor: stringToColor(employee.name) }}>
              {employee.initials
                ? employee.initials
                : getInitials(employee.name)}
            </Avatar>
          </Col>
          <Col className="col-grow chat-user-name">
            <h4>{employee.name}</h4>
          </Col>
          <Col>{exstra}</Col>
        </Row>
      )}

      <div className="chat-board">
        {[...initialMessages, ...messages].length < 1 ? (
          <Empty
            description="Vi fandt ikke noget, vi kunne vise her."
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <ul className="chat-messages" ref={chatBoard}>
            {[...initialMessages, ...messages].map(msg => (
              <li key={msg.id} className={msg.rule}>
                {msg.rule === "admin" && (
                  <small className="message-sender-name">{msg.userName}</small>
                )}
                <p>{msg.message}</p>
                <span className="message-date">
                  {msg.isSent && <Icon type="check" />}
                  {msg.dateString
                    ? msg.dateString
                    : `${formatDate(msg.date)} • ${formatTime(msg.date)}`}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
      <Row
        type="flex"
        align="bottom"
        className={`chat-footer ${loading ? "isSending" : ""}`}
      >
        <Col className="col-grow chat-message-field">
          <TextArea
            onChange={e => setMessage(e.target.value)}
            autoSize={{
              minRows: 1,
              maxRows: 4
            }}
          />
        </Col>
        <Col>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            type="primary"
            shape="circle"
            icon="arrow-right"
            size="large"
          />
        </Col>
      </Row>
    </div>
  )
}

export default ChatWindow

import React, { useEffect } from "react"
import { Select, Icon, Button } from "antd"
import { PropTypes } from "prop-types"
import { useDataApi } from "../../utils/useDataApi"
import { useDrawerState, useDrawerDispatch } from "../../contexts/drawerContext"
import { locationsByProviderId } from "../../services/locationService"

const { Option } = Select

const LocationSelect = ({
  disableAdd,
  disabled,
  placeHolder,
  fieldName,
  initial,
  form,
  providerId,
  preserve
}) => {
  const { setFieldsValue, getFieldValue, getFieldDecorator } = form
  const [
    { isLoading, isError, data: locations },
    setUrl,
    dispatch
  ] = useDataApi("", [])

  const drawerState = useDrawerState()
  const drawerDispatch = useDrawerDispatch()

  useEffect(() => {
    if (providerId) setUrl(locationsByProviderId(providerId))
  }, [providerId, setUrl])

  useEffect(() => {
    if (Array.isArray(locations) && locations.length > 0) {
      const prevValue = getFieldValue(fieldName)
      const check = locations.some(loc => loc.id === prevValue)
      setFieldsValue({ [fieldName]: check ? prevValue : undefined })
    } else {
      setFieldsValue({ [fieldName]: undefined })
    }
  }, [fieldName, getFieldValue, locations, setFieldsValue])

  useEffect(() => {
    const { addedItem, field } = drawerState
    if (field === fieldName && addedItem.id) {
      dispatch({
        type: "ADD_ITEM",
        payload: addedItem
      })
      setFieldsValue({ [fieldName]: addedItem.id })
      drawerDispatch({ type: "RESET" })
    }
  }, [drawerState, fieldName, dispatch, setFieldsValue, drawerDispatch])

  return (
    <>
      {getFieldDecorator(fieldName, {
        initialValue: initial,
        preserve
      })(
        <Select
          showSearch
          disabled={disabled || !providerId || isError}
          loading={isLoading}
          placeholder={placeHolder}
          optionFilterProp="children"
          dropdownRender={menu => (
            <div>
              {menu}
              {!disableAdd && (
                <div className="m-8">
                  <Button
                    type="dashed"
                    block
                    onMouseDown={e => e.preventDefault()}
                    onClick={() =>
                      drawerDispatch({
                        type: "ADD_LOCATION",
                        field: fieldName,
                        providerId
                      })
                    }
                  >
                    <Icon type="plus" /> Ny Lokation
                  </Button>
                </div>
              )}
            </div>
          )}
        >
          {Array.isArray(locations) && locations.length
            ? locations.map(loc => (
                <Option key={loc.id} value={loc.id}>
                  {loc.name}
                </Option>
              ))
            : null}
        </Select>
      )}
    </>
  )
}

LocationSelect.defaultProps = {
  disableAdd: false,
  initial: undefined,
  disabled: false,
  fieldName: "locationId",
  preserve: false,
  placeHolder: "Vælg lokation.."
}
LocationSelect.propsType = {
  disableAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  initial: PropTypes.number,
  placeHolder: PropTypes.string,
  form: PropTypes.object.isRequired,
  preserve: PropTypes.bool,
  providerId: PropTypes.number
}

export default LocationSelect

import jwtDecode from "jwt-decode"
import http, { setJwt } from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/auth`
const tokenKey = "token"
const refreshTokenKey = "refreshToken"

setJwt(getJwt())

export async function login(email, password) {
  const { data } = await http.post(apiEndpoint, {
    email,
    password
  })
  saveJwt(data.token)
  saveRefreshJwt(data.refreshToken)
}

export function logout() {
  localStorage.removeItem(tokenKey)
  localStorage.removeItem(refreshTokenKey)
}

export function getCurrentUser() {
  try {
    const jwt = getJwt(tokenKey)
    const user = jwtDecode(jwt)
    if (user.exp < Date.now() / 1000) return null
    return user
  } catch (ex) {
    return null
  }
}

export async function refreshJwt() {
  const tokenObj = {
    token: getJwt(),
    refreshToken: getRefreshJwt()
  }
  global.log(tokenObj)
  try {
    const refreshResponse = await http.post(`${apiEndpoint}/refresh`, tokenObj)
    global.log("refreshResponse:", refreshResponse)
    setJwt(refreshResponse.data.token)
    saveJwt(refreshResponse.data.token)
    saveRefreshJwt(refreshResponse.data.refreshToken)
    return refreshResponse
  } catch (err) {
    global.log("error:", err.response)
    return err
  }
}

export function saveJwt(token) {
  return localStorage.setItem(tokenKey, token)
}

export function saveRefreshJwt(refreshToken) {
  return localStorage.setItem(refreshTokenKey, refreshToken)
}

export function getJwt() {
  return localStorage.getItem(tokenKey)
}

export function getRefreshJwt() {
  return localStorage.getItem(refreshTokenKey)
}

export default {
  login,
  logout,
  getCurrentUser,
  getJwt
}

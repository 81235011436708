import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/provider`

export const providersUrl = `${apiEndpoint}/list`

function providerUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getProviders() {
  return http.get(`${apiEndpoint}/List`)
}

export function getProvider(providerId) {
  return http.get(providerUrl(providerId))
}

export function saveProvider(provider) {
  if (provider.id !== 0) return http.put(apiEndpoint, provider)

  return http.post(apiEndpoint, provider)
}

export function deleteProvider(providerId) {
  return http.delete(providerUrl(providerId))
}

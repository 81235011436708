import React, { Component } from "react"
import { Form, message, Row, Select, Col, Card, Spin, Input } from "antd"
import {
  saveParticipantCompany,
  getParticipantCompany,
  getParticipantTypes
} from "../services/participantCompaniesService"
import { getCountries } from "../services/countryService"
import FormActions from "../components/common/formActions"
import AddressSet from "../components/common/addressSet"
import PageTitle from "../components/common/PageTitle"
import VatNrField from "../components/common/vatNrField"

const { Option } = Select

class ParticipantCompaniesForm extends Component {
  state = {
    loading: false,
    participantTypes: [],

    countries: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: participantTypes } = await getParticipantTypes()
    const { data: countries } = await getCountries()
    this.populateForm(this.props.match.params.id)
    this.setState({
      loading: false,
      participantTypes,

      countries
    })
  }

  populateForm = async id => {
    if (id === "new") return
    try {
      const { data: participantCompany } = await getParticipantCompany(id)
      this.props.form.setFieldsValue({
        id: participantCompany.id,
        name: participantCompany.name,
        vatNo: participantCompany.vatNo,
        customerNo: participantCompany.customerNo,
        departmentNo: participantCompany.departmentNo,
        phone: participantCompany.phone,
        email: participantCompany.email,
        streetName: participantCompany.streetName,
        streetNumber: participantCompany.streetNumber,
        city: participantCompany.city,
        zipCode: participantCompany.zipCode,
        countryId: participantCompany.countryId,
        participantTypeId: participantCompany.participantTypeId
      })
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found")
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        global.log("Received values of form: ", values)
        await saveParticipantCompany(values)
        this.setState({ loading: false })
        message.success("Firma oprettet", 3)
        this.props.history.push("/participantCompanies")
      } else {
        this.setState({ loading: false })
      }
    })
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { loading, participantTypes, countries } = this.state
    const formItemLayout = { colon: false }
    const participantCompanyName = getFieldValue("name")

    getFieldDecorator("id", { initialValue: 0 })

    return (
      <>
        <PageTitle title={this.props.title} />
        <Card
          title={
            !participantCompanyName || participantCompanyName === ""
              ? "Nyt firma"
              : participantCompanyName
          }
          className="card"
          bordered={false}
          actions={[
            <FormActions
              key={1}
              onSubmit={this.handleSubmit}
              cancelLink="/participantCompanies"
            />
          ]}
        >
          {loading ? (
            <div className="spin-card-overlay">
              <Spin />
            </div>
          ) : null}
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={15}>
              <Col md={{ span: 10 }} xs={{ span: 24 }}>
                <VatNrField form={form} />
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={{ span: 12 }}>
                <Form.Item {...formItemLayout} label="Navn">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Udfyld venligst feltet"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={{ span: 10 }} xs={{ span: 10 }}>
                <Form.Item {...formItemLayout} label="Type">
                  {getFieldDecorator("participantTypeId", {
                    rules: [
                      {
                        required: true,
                        message: "Vælg venligst et firma"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Vælg en type"
                      style={{ width: "100%" }}
                    >
                      {participantTypes.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col md={{ span: 7 }} xs={{ span: 7 }}>
                <Form.Item {...formItemLayout} label="Kunde nr.">
                  {getFieldDecorator("customerNo")(<Input />)}
                </Form.Item>
              </Col>
              <Col md={{ span: 7 }} xs={{ span: 7 }}>
                <Form.Item {...formItemLayout} label="Afd. nr.">
                  {getFieldDecorator("departmentNo")(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={12}>
                <div className="form-group-title">Adresse</div>
                <AddressSet
                  getFieldDecorator={getFieldDecorator}
                  countries={countries}
                />
              </Col>
              <Col span={12}>
                <div className="form-group-title">Kontakt info</div>
                <Form.Item {...formItemLayout}>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "Udfyld venligst feltet med Email"
                      },
                      {
                        required: true,
                        message: "Udfyld venligst feltet"
                      }
                    ]
                  })(<Input placeholder="Email" />)}
                </Form.Item>
                <Form.Item {...formItemLayout}>
                  {getFieldDecorator("phone")(
                    <Input placeholder="Telefon nr." />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    )
  }
}
export default Form.create()(ParticipantCompaniesForm)

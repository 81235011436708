import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/Tag`

function tagUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getTags() {
  return http.get(`${apiEndpoint}/List`)
}

export const fetchUrl = `${apiEndpoint}/List`

export function getTag(tagId) {
  return http.get(tagUrl(tagId))
}

export function saveTag(tag) {
  if (tag.id !== 0) return http.put(apiEndpoint, tag)

  return http.post(apiEndpoint, tag)
}

export function deleteTag(tagId) {
  return http.delete(tagUrl(tagId))
}

import React, { useState } from "react"
import { Select, Button, Icon } from "antd"
import { useDataApi } from "../../utils/useDataApi"
import { fetchUrl } from "../../services/tagService"

const { Option } = Select

const InputTags = ({ form }) => {
  const initalData = form.getFieldValue("tags") || []
  const [{ data, isLoading, isError }, setUrl] = useDataApi(
    fetchUrl,
    initalData
  )
  const [newOptions, setNewOptions] = useState([])
  const handleSearch = value => setUrl(`${fetchUrl}?search=${value}`)
  const handleAdd = value =>
    setNewOptions([
      ...newOptions,
      {
        id: `0_${value.toString()}`,
        name: value
      }
    ])
  const clearSelectSearch = () => setUrl(fetchUrl)

  return form.getFieldDecorator("tags")(
    <Select
      showSearch
      mode="multiple"
      onSearch={handleSearch}
      placeholder="Tags"
      showArrow={false}
      onBlur={clearSelectSearch}
      loading={isLoading}
      labelInValue
      optionFilterProp="children"
      dropdownRender={(menu, props) => {
        const query = props.inputValue

        return (
          <>
            {menu}
            {query !== " " &&
            query.length > 0 &&
            !menu.props.menuItems.find(
              item =>
                typeof item.props.children === "string" &&
                item.props.children.toLowerCase() === query.toLowerCase()
            ) ? (
              <div className="m-8">
                <Button
                  type="dashed"
                  block
                  onMouseDown={() => handleAdd(query)}
                >
                  <Icon type="plus" />
                  {query}
                </Button>
              </div>
            ) : null}
          </>
        )
      }}
    >
      {!isError &&
        [...newOptions, ...data].map(d => (
          <Option key={d.id} value={d.id}>
            {d.name}
          </Option>
        ))}
    </Select>
  )
}

export default InputTags

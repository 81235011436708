import React from "react"
import { Upload, Icon, Modal } from "antd"
import DraggableList from "./draggableList"
import { beforeUploadCheck, dummyRequest } from "../../utils/imageFunctions"

const { Dragger } = Upload

const ImageUploader = ({
  list,
  previewImage,
  onChange,
  onDragEnd,
  onRemove,
  onPreview,
  onCancelPreview
}) => {
  return (
    <div className="UploadImg">
      <Dragger
        multiple
        className="Image-uploader"
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUploadCheck}
        onChange={onChange}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">
          Klik eller træk filer til denne boks for at uploade
        </p>
      </Dragger>
      <DraggableList
        list={list}
        onDragEnd={onDragEnd}
        onRemove={onRemove}
        onPreview={onPreview}
      />
      {previewImage && (
        <Modal
          visible
          title={previewImage.name}
          footer={null}
          onCancel={onCancelPreview}
        >
          <img
            alt={previewImage.name}
            className="modal-img"
            src={previewImage.path}
          />
        </Modal>
      )}
    </div>
  )
}
export default ImageUploader

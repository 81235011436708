import React, { useEffect } from "react"
import { Select, Icon, Button } from "antd"
import { PropTypes } from "prop-types"
import { useDataApi } from "../../utils/useDataApi"
import { useDrawerState, useDrawerDispatch } from "../../contexts/drawerContext"
import { providerContactsUrl } from "../../services/providerContactsService"

const { Option } = Select

const ExtContactSelect = ({
  disableAdd,
  disabled,
  placeHolder,
  fieldName,
  initial,
  form,
  providerId,
  preserve
}) => {
  const { setFieldsValue, getFieldDecorator } = form
  const [
    { isLoading, isError, data: extContacts },
    setUrl,
    dispatch
  ] = useDataApi("", [])

  const drawerState = useDrawerState()
  const drawerDispatch = useDrawerDispatch()

  useEffect(() => {
    if (providerId) setUrl(providerContactsUrl(providerId))
  }, [providerId, setUrl])

  useEffect(() => {
    const { addedItem, field } = drawerState
    if (!disableAdd && field === fieldName && addedItem.id) {
      dispatch({
        type: "ADD_ITEM",
        payload: addedItem
      })
      setFieldsValue({ [fieldName]: addedItem.id })
      drawerDispatch({ type: "RESET" })
    }
  }, [
    disableAdd,
    dispatch,
    drawerDispatch,
    drawerState,
    fieldName,
    setFieldsValue
  ])

  return (
    <>
      {isError && "Kunne ikke hente Udbyder"}
      {getFieldDecorator(fieldName, {
        initialValue: initial,
        preserve
      })(
        <Select
          showSearch
          disabled={disabled || !providerId}
          loading={isLoading}
          placeholder={placeHolder}
          optionFilterProp="children"
          dropdownRender={menu => (
            <div>
              {menu}
              {!disableAdd && (
                <div className="m-8">
                  <Button
                    type="dashed"
                    block
                    onMouseDown={e => e.preventDefault()}
                    onClick={() =>
                      drawerDispatch({
                        type: "ADD_CONTACT",
                        field: fieldName,
                        providerId
                      })
                    }
                  >
                    <Icon type="plus" /> Ny kontaktperson
                  </Button>
                </div>
              )}
            </div>
          )}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {extContacts &&
            extContacts.map(loc => (
              <Option key={loc.id} value={loc.id}>
                {loc.name}
              </Option>
            ))}
        </Select>
      )}
    </>
  )
}

ExtContactSelect.defaultProps = {
  disableAdd: false,
  initial: undefined,
  disabled: false,
  fieldName: "contactId",
  preserve: false,
  placeHolder: "Vælg kontaktperson.."
}
ExtContactSelect.propsType = {
  disableAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  initial: PropTypes.number,
  placeHolder: PropTypes.string,
  form: PropTypes.object.isRequired,
  preserve: PropTypes.bool,
  providerId: PropTypes.number
}

export default ExtContactSelect

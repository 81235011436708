import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/Participant`

function participantUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getParticipants() {
  return http.get(`${apiEndpoint}/List`)
}

export function getParticipant(participantId) {
  return http.get(participantUrl(participantId))
}

export function saveParticipant(participant) {
  if (participant.id !== 0) return http.put(apiEndpoint, participant)

  return http.post(apiEndpoint, participant)
}

export function deleteParticipant(participantId) {
  return http.delete(participantUrl(participantId))
}

import React, { Component } from "react"
import { Form, Row, Col, message, Input, Card, TreeSelect, Spin } from "antd"
import { getCategories } from "../services/categoryService"
import { getEvent, saveEvent } from "../services/eventService"
import { mapTreeNode } from "../utils/mapTreeNode"
import { getDate } from "../utils/dateFunc"
import FormActions from "../components/common/formActions"
import Uploader from "../components/common/uploader"
import EventDates from "../components/common/eventDates"
import PageTitle from "../components/common/PageTitle"
import InputTags from "../components/common/InputTags"
import ProviderSelect from "../components/providerSelect"
import LocationSelect from "../components/common/locationSelect"

const { TextArea } = Input

class EventForm extends Component {
  state = {
    categories: [],
    loading: "",
    images: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: categories } = await getCategories()
    this.setState({ categories })
    await this.populateEvent()
    this.setState({ loading: false })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { validateFields, getFieldValue } = this.props.form
    validateFields(async (err, values) => {
      if (!err) {
        const { images } = this.state
        const dates = getFieldValue("dates")
        const newDates = dates.map(date => ({
          id: date.id,
          name: date.name,
          start: getDate(date.dateRange[0], date.startTime),
          end: getDate(date.dateRange[1], date.endTime)
        }))
        const newEvent = {
          id: values.id,
          name: values.name,
          categoryId: values.category,
          providerId: values.providerId,
          locationId: values.locationId,
          tags: values.tags,
          description: values.description,
          link: values.link,
          dates: [...newDates],
          images: [...images]
        }
        global.log("Received values of form: ", newEvent)
        this.setState({ loading: true })
        try {
          await saveEvent(newEvent)
          message.success("Event er gemt", 3)
          this.setState({ loading: false })
          this.props.history.push("/events")
        } catch (error) {
          global.log(error)
          message.error("Event er ikke gemt, prøv igen senere", 3)
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUpload = images => this.setState({ images })

  async populateEvent() {
    const { id: eventId } = this.props.match.params

    try {
      if (eventId === "new") return
      const { data: event } = await getEvent(eventId)
      const { images } = event
      const { form } = this.props
      this.setState({
        images
      })
      form.setFieldsValue({
        id: event.id,
        name: event.name,
        link: event.link,
        description: event.description,
        category: event.category.id,
        providerId: event.provider.id,
        tags: event.tags,
        locationId: event.locationId,
        initDates: event.dates
      })
      global.log(event)
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found")
    }
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { categories, loading, images } = this.state
    const formItemLayout = { colon: false }

    getFieldDecorator("id", { initialValue: 0 })
    getFieldDecorator("initDates", { initialValue: [] })

    const eventName = getFieldValue("name")

    return (
      <>
        <PageTitle title={this.props.title} />
        <Card
          title={!eventName || eventName === "" ? "Nyt event" : eventName}
          className="card"
          bordered={false}
          actions={[
            <FormActions
              key={1}
              onSubmit={this.handleSubmit}
              cancelLink="/events"
            />
          ]}
        >
          {loading ? (
            <div className="spin-card-overlay">
              <Spin />
            </div>
          ) : null}
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={15}>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Event navn">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter Event Name!"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Udbyder">
                  <ProviderSelect form={form} />
                </Form.Item>
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Kategori">
                  {getFieldDecorator("category")(
                    <TreeSelect
                      showSearch
                      treeNodeFilterProp="title"
                      searchPlaceholder="Søg.."
                      onSearch={this.categoriesSearch}
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto"
                      }}
                      placeholder="Please select"
                      allowClear
                    >
                      {mapTreeNode(categories, form.getFieldValue("category"))}
                    </TreeSelect>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Lokation">
                  <LocationSelect
                    form={form}
                    providerId={form.getFieldValue("providerId")}
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Link">
                  {getFieldDecorator("link")(<Input addonBefore="http://" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={24}>
                <EventDates form={form} />
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={{ span: 11 }} xs={{ span: 24 }}>
                <Form.Item {...formItemLayout} label="Beskrivelse">
                  {getFieldDecorator("description")(<TextArea rows={6} />)}
                </Form.Item>
                <Form.Item>
                  <InputTags form={form} />
                </Form.Item>
              </Col>
              <Col
                md={{
                  span: 12,
                  offset: 1
                }}
                xs={{ span: 24 }}
              >
                <Form.Item {...formItemLayout} label="Billeder">
                  <Uploader
                    onUploadChange={this.handleUpload}
                    images={images}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    )
  }
}

export default Form.create()(EventForm)

import React, { useEffect } from "react"
import { Select, Icon, Button } from "antd"
import { PropTypes } from "prop-types"
import { useDataApi } from "../utils/useDataApi"
import { providersUrl } from "../services/providerService"
import { useDrawerState, useDrawerDispatch } from "../contexts/drawerContext"

const { Option } = Select

const ProviderSelect = ({
  form,
  disableAdd,
  initial,
  fieldName,
  placeHolder,
  disabled,
  preserve
}) => {
  const drawerState = useDrawerState()
  const drawerDispatch = useDrawerDispatch()
  const { addedItem, type } = drawerState
  const { getFieldDecorator, setFieldsValue } = form
  // eslint-disable-next-line no-unused-vars
  const [
    { isLoading, isError, data: providers },
    // eslint-disable-next-line no-unused-vars
    setUrl,
    dispatch
  ] = useDataApi(providersUrl, [])

  useEffect(() => {
    if (type === "provider" && addedItem.id) {
      dispatch({
        type: "ADD_ITEM",
        payload: addedItem
      })
      setFieldsValue({ [fieldName]: addedItem.id })

      drawerDispatch({ type: "RESET" })
    }
  }, [addedItem, dispatch, drawerDispatch, fieldName, setFieldsValue, type])

  return (
    <>
      {isError && "Kunne ikke hente Udbyder"}
      {getFieldDecorator(fieldName, {
        initialValue: initial,
        preserve
      })(
        <Select
          showSearch
          disabled={disabled}
          loading={isLoading}
          placeholder={placeHolder}
          optionFilterProp="children"
          dropdownRender={menu => (
            <div>
              {menu}
              {!disableAdd && (
                <div className="m-8">
                  <Button
                    type="dashed"
                    block
                    onMouseDown={e => e.preventDefault()}
                    onClick={() =>
                      drawerDispatch({
                        type: "ADD_PROVIDER",
                        field: fieldName,
                        providerId: 0
                      })
                    }
                  >
                    <Icon type="plus" /> Ny udbyder
                  </Button>
                </div>
              )}
            </div>
          )}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {providers &&
            providers.map(d => (
              <Option value={d.id} key={d.id}>
                {d.name}
              </Option>
            ))}
        </Select>
      )}
    </>
  )
}

ProviderSelect.defaultProps = {
  disableAdd: false,
  initial: undefined,
  disabled: false,
  fieldName: "providerId",
  preserve: false,
  placeHolder: "Vælg en udbyder.."
}
ProviderSelect.propsType = {
  disableAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  placeHolder: PropTypes.string,
  initial: PropTypes.number,
  form: PropTypes.object.isRequired,
  preserve: PropTypes.bool
}

export default ProviderSelect

import React, { Component } from "react"
import { Form, Input, Button } from "antd"
import { getCompanyByVatNr } from "../../services/vatNrService"

class VatNrField extends Component {
  state = {
    isLoading: false,
    fetchStatus: "",
    error: {}
  }

  handleChange = () => {
    this.setState({
      fetchStatus: "",
      error: {}
    })
  }

  fetchCompany = async () => {
    this.setState({ isLoading: true })
    const { getFieldValue, setFieldsValue } = this.props.form
    const value = getFieldValue("vatNo")
    try {
      const { data } = await getCompanyByVatNr(value)
      delete data.id
      setFieldsValue(data)
      this.setState({
        fetchStatus: "success",
        isLoading: false
      })
      global.log(data)
    } catch ({ response }) {
      global.log(response)
      if (response) {
        const fetchStatus = response.status === 404 ? "warning" : "error"
        this.setState({
          error: response,
          fetchStatus,
          isLoading: false
        })
      }
    }
  }

  getValidationStatus = () => {
    const { error } = this.state
    if (error && error.status)
      switch (error.status) {
        case 404:
          return "warning"
        default:
          return "error"
      }
    else return ""
  }

  render() {
    const { isLoading, error, fetchStatus } = this.state
    const { getFieldDecorator } = this.props.form

    return (
      <Form.Item
        className="vat-nr-wrapper"
        validateStatus={fetchStatus}
        hasFeedback
        help={error.data ? error.data : ""}
        label="CVR nr."
      >
        {getFieldDecorator("vatNo")(
          <Input
            onChange={this.handleChange}
            disabled={isLoading}
            maxLength={12}
            className="vat-nr-field"
            addonAfter={
              <Button
                onClick={this.fetchCompany}
                disabled={isLoading}
                className="vat-nr-fetch-btn"
                type="primary"
                icon={isLoading ? "loading" : "sync"}
              />
            }
          />
        )}
      </Form.Item>
    )
  }
}

export default VatNrField

import React from "react"
import { Row, Input, Col, Switch } from "antd"

const OrderParticipants = ({
  company,
  companyKey: k,
  onSwitch,
  getFieldDecorator
}) => {
  return company.participants.map((par, index) => (
    <Row
      type="flex"
      key={par.id}
      align="middle"
      className="order-details-row order-participant-item"
    >
      <Col className="col-grow">
        <h4 className="">
          {par.name} <small>{par.reason}</small>
        </h4>
      </Col>
      <Col>
        <div className="order-participant-ticket">
          {getFieldDecorator(
            `companies[${k}].participants[${index}].ticket.quantity`,
            {
              initialValue: par.ticket.quantity
            }
          )(<Input type="hidden" />)}
          {getFieldDecorator(
            `companies[${k}].participants[${index}].ticket.id`,
            {
              initialValue: par.ticket.id
            }
          )(<Input type="hidden" />)}
          {par.ticket.quantity > 1 && `${par.ticket.quantity} stk. `}
          {par.ticket.name}
        </div>
      </Col>
      <Col className="order-switch-col">
        {getFieldDecorator(`companies[${k}].participants[${index}].id`, {
          initialValue: par.id
        })(<Input type="hidden" />)}
        {getFieldDecorator(
          `companies[${k}].participants[${index}].isParticipating`,
          {
            initialValue: par.accepted || true,
            valuePropName: "checked"
          }
        )(<Switch onClick={onSwitch} size="small" />)}
      </Col>
    </Row>
  ))
}

export default OrderParticipants

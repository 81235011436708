import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/Country`

export const countriesUrl = `${apiEndpoint}/List`

function countryUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getCountries() {
  return http.get(countriesUrl)
}

export function getCountry(categoryId) {
  return http.get(countryUrl(categoryId))
}

// we will use these functions when we have a special page to edite countries.

/* export function saveCountry(category) {
  if (category.id !== 0) return http.put(apiEndpoint, category)

  return http.post(apiEndpoint, category)
}

export function deleteCountry(categoryId) {
  return http.delete(countryUrl(categoryId))
} */

import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/TicketType`

function ticketTypeUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getTicketTypes() {
  return http.get(`${apiEndpoint}/List`)
}

export function getTicketType(ticketTypeId) {
  return http.get(ticketTypeUrl(ticketTypeId))
}

export function saveTicketType(ticketType) {
  if (ticketType.id !== 0) return http.put(apiEndpoint, ticketType)

  return http.post(apiEndpoint, ticketType)
}

export function deleteTicketType(ticketTypeId) {
  return http.delete(ticketTypeUrl(ticketTypeId))
}

import React, { useEffect } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { Menu, Icon, Layout } from "antd"
import { ReactComponent as Logo } from "../assets/img/logo.svg"
import { routes, flatRoutes } from "../routes/appRoutes"

const { Sider } = Layout
const { SubMenu } = Menu

const SideNav = () => {
  const location = useLocation()
  useEffect(() => {
    const pathname = location.pathname.split("/")[1]
    const currentRoute = flatRoutes.find(obj => obj.path === `/${pathname}`)
    document.title = currentRoute
      ? `${currentRoute.title} - One Lounge`
      : "One Lounge"
  }, [location])

  return (
    <Sider breakpoint="lg" collapsedWidth="0" className="side-nav">
      <div className="logo">
        <Link className="navbar-brand" to="/">
          <Logo height="50" alt="OneLounge" />
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["/"]}
        selectedKeys={[location.pathname]}
      >
        {mapMenuItems(routes)}
      </Menu>
    </Sider>
  )
}

const mapMenuItems = menu => {
  return menu.map(item => {
    if (!item.showInMenu) return null
    if (Array.isArray(item.subMenu) && item.subMenu.length > 0) {
      return (
        <SubMenu
          key={item.title}
          title={
            <span>
              {item.icon && <Icon type={item.icon} />}
              <span>{item.title}</span>
            </span>
          }
        >
          {mapMenuItems(item.subMenu)}
        </SubMenu>
      )
    }
    return (
      <Menu.Item key={item.path}>
        <NavLink to={item.path}>
          {item.icon && <Icon type={item.icon} />}
          <span className="nav-text">{item.title}</span>
        </NavLink>
      </Menu.Item>
    )
  })
}

export default SideNav

import React from "react"
import { Link } from "react-router-dom"
import { Button } from "antd"

const FormActions = ({ onSubmit, cancelLink, onCancel }) => {
  return (
    <div className="submit-btn">
      {cancelLink ? (
        <Button>
          <Link to={cancelLink}>Annuller</Link>
        </Button>
      ) : (
        <Button onClick={onCancel}>Annuller</Button>
      )}
      <Button type="primary" onClick={onSubmit}>
        Gem
      </Button>
    </div>
  )
}

export default FormActions

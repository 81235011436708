import React, { Component } from "react"
import { Input } from "antd"
import {
  unFormatCurrency,
  formatCurrencyForJSON,
  formatCurrencyFromJSON,
  priceReg
} from "../../utils/currencyFunc"

class PriceInput extends Component {
  state = { comma: false }

  componentDidMount() {
    const { initialValue } = this.props
    if (initialValue) this.onChange(initialValue)
  }

  onChange = value => {
    const { fieldName, form } = this.props
    const { setFieldsValue } = form
    const { comma } = this.state
    const dotsFreeValue = unFormatCurrency(value)

    if (value === "") {
      setFieldsValue({ [fieldName]: "" })
      return
    }

    if (priceReg.test(dotsFreeValue)) {
      const newValue = formatCurrencyForJSON(dotsFreeValue, comma)
      if (comma) this.setState({ comma: false })
      setFieldsValue({ [fieldName]: newValue })
      if (dotsFreeValue.slice(dotsFreeValue.length - 1) === ",")
        this.setState({ comma: true })
    }
  }

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    this.setState({ comma: false })
  }

  render() {
    const { fieldName, preserve, disabled, form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const formValue = getFieldValue(fieldName)
    const value = formValue && formatCurrencyFromJSON(formValue)
    getFieldDecorator(fieldName, { preserve })

    return (
      <Input
        value={value}
        disabled={disabled}
        className="price-input"
        onChange={e => this.onChange(e.target.value)}
        // onBlur={this.onBlur}
        addonAfter="DKK"
      />
    )
  }
}

export default PriceInput

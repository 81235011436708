import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/ProviderContact`

function providerUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function providerContactsUrl(id) {
  return `${apiEndpoint}/List?providerId=${id}`
}

export function getProviderContacts(id) {
  return http.get(`${apiEndpoint}/List?providerId=${id}`)
}

export function getProviderContact(providerId) {
  return http.get(providerUrl(providerId))
}

export function saveProviderContact(value) {
  return http.post(value)
}

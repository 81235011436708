import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Table, Popconfirm, Input, Button, Icon, message } from "antd"
import Highlighter from "react-highlight-words"
import {
  deleteParticipant,
  getParticipants
} from "../services/participantService"
import PageTitle from "../components/common/PageTitle"

class Participants extends Component {
  state = {
    searchText: "",
    loading: false,
    participants: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: participants } = await getParticipants()
    this.setState({
      participants,
      loading: false
    })
  }

  handleDelete = async participant => {
    const originalParticipants = [...this.state.participants]
    const { id } = participant
    const participants = originalParticipants.filter(
      e => e.id !== participant.id
    )
    this.setState({
      loading: true,
      participants
    })

    try {
      await deleteParticipant(id)
      this.setState({ loading: false })
      message.success("Deleted")
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        message.error("Not deleted")

      this.setState({
        participants: originalParticipants,
        loading: false
      })
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    }
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: "" })
  }

  render() {
    const { searchText } = this.state
    const columns = [
      {
        title: "Navn",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name"),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
        render: (text, index) => (
          <Link to={`/participants/${index.id}`}>
            {searchText ? (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />
            ) : (
              text
            )}
          </Link>
        )
      },
      {
        title: "Firma",
        dataIndex: "company.name",
        key: "companyname",
        render: (text, record) => (
          <Link to={`/participantCompanies/${record.companyId}`}>{text}</Link>
        )
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Mobil nr.",
        dataIndex: "mobile",
        key: "mobile"
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        width: 100,
        className: "action-column",
        render: record => (
          <Popconfirm
            title={`Er du sikker på at du vil slette ${record.name} ?`}
            okText="Ja"
            cancelText="Nej"
            onConfirm={() => this.handleDelete(record)}
          >
            <div className="delete-icon">
              <Icon type="delete" />
            </div>
          </Popconfirm>
        )
      }
    ]

    return (
      <>
        <PageTitle
          title={this.props.title}
          exstra={
            <Button type="primary">
              <Link to="/participants/new">Ny kontaktperson</Link>
            </Button>
          }
        />
        <Table
          loading={this.state.loading}
          rowKey="id"
          columns={columns}
          dataSource={this.state.participants}
          onChange={this.onChange}
          className="table-card"
        />
      </>
    )
  }
}

export default Participants

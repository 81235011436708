import { Route } from "react-router-dom"
import Events from "../screens/events"
import EventsForm from "../screens/eventsForm"

import Departments from "../screens/departments"
import DepartmentsForm from "../screens/departmentsForm"

import Employees from "../screens/employees"
import EmployeeForm from "../screens/employeeForm"

import Participants from "../screens/participants"
import ParticipantsForm from "../screens/participantsForm"

import ParticipantCompanies from "../screens/participantCompanies"
import ParticipantCompaniesForm from "../screens/participantCompaniesForm"

import Dashboard from "../screens/dashboard"
import Sponsors from "../screens/sponsors"
import SponsorsForm from "../screens/sponsorsForm"
import NotFound from "../screens/notFound"
import LoginForm from "../screens/loginForm"
import RegisterForm from "../screens/registerForm"
import Logout from "../screens/logout"
import Orders from "../screens/orders"
import Providers from "../screens/providers"
import ProviderForm from "../screens/providerForm"
import ProtectedRoute from "../components/common/protectedRoute"

export const routes = [
  {
    showInMenu: false,
    path: "/register",
    title: "Registerer",
    component: RegisterForm,
    route: Route,
    componentParams: {}
  },
  {
    showInMenu: false,
    path: "/login",
    title: "Logind",
    component: LoginForm,
    route: Route,
    componentParams: {}
  },
  {
    showInMenu: false,
    path: "/logout",
    title: "Logout",
    component: Logout,
    route: Route,
    componentParams: {}
  },
  {
    showInMenu: true,
    path: "/dashboard",
    icon: "dashboard",
    title: "Dashboard",
    component: Dashboard,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: false,
    path: "/sponsors/:id",
    title: "Sponsorater",
    component: SponsorsForm,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: true,
    path: "/sponsors",
    icon: "bank",
    title: "Sponsorater",
    component: Sponsors,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: false,
    path: "/events/:id",
    title: "Events",
    component: EventsForm,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: true,
    path: "/events",
    icon: "notification",
    title: "Events",
    component: Events,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: true,
    path: "/orders",
    icon: "tags",
    title: "Billetter",
    component: Orders,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: false,
    path: "/departments/:id",
    title: "Afdelinger",
    component: DepartmentsForm,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: true,
    path: "/departments",
    icon: "apartment",
    title: "Afdelinger",
    component: Departments,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: false,
    path: "/employees/:id",
    title: "Medarbejder",
    component: EmployeeForm,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: true,
    path: "/employees",
    icon: "team",
    title: "Medarbejder",
    component: Employees,
    route: ProtectedRoute,
    componentParams: {}
  },

  {
    showInMenu: false,
    path: "/providers/:id",
    title: "Udbydere",
    component: ProviderForm,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: true,
    path: "/providers",
    title: "Udbydere",
    icon: "contacts",
    component: Providers,
    route: ProtectedRoute,
    componentParams: {}
  },
  {
    showInMenu: true,
    path: null,
    icon: "idcard",
    title: "Kontakter",
    subMenu: [
      {
        showInMenu: false,
        path: "/participants/:id",
        title: "Personer",
        component: ParticipantsForm,
        route: ProtectedRoute,
        componentParams: {}
      },
      {
        showInMenu: true,
        path: "/participants",
        title: "Personer",
        component: Participants,
        route: ProtectedRoute,
        componentParams: {}
      },
      {
        showInMenu: false,
        path: "/participantCompanies/:id",
        title: "Firmaer",
        component: ParticipantCompaniesForm,
        route: ProtectedRoute,
        componentParams: {}
      },
      {
        showInMenu: true,
        path: "/participantCompanies",
        title: "Firmaer",
        component: ParticipantCompanies,
        route: ProtectedRoute,
        componentParams: {}
      }
    ]
  },
  {
    showInMenu: false,
    path: "/not-found",
    title: "Side ikke fundet",
    component: NotFound,
    route: Route,
    componentParams: {}
  }
]

export const flatRoutes = routes
  .map(item => item.subMenu || item)
  .reduce((acc, val) => acc.concat(val), [])

import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/ParticipantCompany`
const apiEndpointForParticipantType = `${oneLoungeApi}/ParticipantCompanyType`

function participantCompanyUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getParticipantCompanies() {
  return http.get(`${apiEndpoint}/List`)
}

export function getParticipantTypes() {
  return http.get(`${apiEndpointForParticipantType}/List`)
}

export function getParticipantCompany(participantCompanyId) {
  return http.get(participantCompanyUrl(participantCompanyId))
}

export function saveParticipantCompany(participantCompany) {
  if (participantCompany.id !== 0)
    return http.put(apiEndpoint, participantCompany)

  return http.post(apiEndpoint, participantCompany)
}

export function deleteParticipantCompany(participantCompanyId) {
  return http.delete(participantCompanyUrl(participantCompanyId))
}

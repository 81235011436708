import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/location`
export const locationsUrl = `${apiEndpoint}/list`

export function locationsByProviderId(id) {
  return `${locationsUrl}/${id}`
}

function locationUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getLocations() {
  return http.get(`${apiEndpoint}/List`)
}

export function getLocation(locationId) {
  return http.get(locationUrl(locationId))
}

export function saveLocation(location) {
  return http.post(apiEndpoint, location)
}

export function deleteLocation(locationId) {
  return http.delete(locationUrl(locationId))
}

import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Table, Popconfirm, Input, Button, Icon, message } from "antd"
import Highlighter from "react-highlight-words"
import { deleteSponsor, getSponsors } from "../services/sponsorService"
import { formatDate } from "../utils/dateFunc"
import PageTitle from "../components/common/PageTitle"

class Sponsors extends Component {
  state = {
    searchText: "",
    loading: false,
    sponsors: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: sponsors } = await getSponsors()
    this.setState({
      sponsors,
      loading: false
    })
  }

  handleDelete = async sponsor => {
    const originalSponsors = [...this.state.sponsors]
    const { id } = sponsor
    const sponsors = originalSponsors.filter(e => e.id !== sponsor.id)
    this.setState({
      loading: true,
      sponsors
    })

    try {
      await deleteSponsor(id)
      this.setState({ loading: false })
      message.success("Slettet")
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        message.error("Ikke slettet, prøv igen senere.")

      this.setState({
        sponsors: originalSponsors,
        loading: false
      })
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    }
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: "" })
  }

  render() {
    const { searchText } = this.state
    const columns = [
      {
        title: "Navn",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Link to={`/sponsors/${record.id}`}>{text}</Link>
        )
      },
      {
        title: "Udbyder",
        dataIndex: "providerName",
        key: "providerName",
        ...this.getColumnSearchProps("providerName"),
        sorter: (a, b) => a.providerName.localeCompare(b.providerName),
        sortDirections: ["descend", "ascend"],
        render: text =>
          searchText ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: "#ffc069",
                padding: 0
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          )
      },
      {
        title: "Budget",
        dataIndex: "budget",
        className: "text-right",
        key: "budget",
        render: text => `DKK ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      {
        title: "Startdato",
        className: "text-right",
        dataIndex: "dateStart",
        key: "dateStart",
        render: text => text && formatDate(text)
      },
      {
        title: "Udløbsdato",
        className: "text-right",
        dataIndex: "dateStop",
        key: "dateStop",
        render: text => text && formatDate(text)
      },
      {
        title: "Ansvarlig",
        dataIndex: "responsible",
        key: "responsible"
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        width: 100,
        className: "action-column",
        render: record => (
          <Popconfirm
            title={
              <div>
                Er du sikker på at du vil slette <strong>{record.name}</strong>?
              </div>
            }
            okText="Ja"
            cancelText="Nej"
            onConfirm={() => this.handleDelete(record)}
          >
            <div className="delete-icon">
              <Icon type="delete" />
            </div>
          </Popconfirm>
        )
      }
    ]

    return (
      <>
        <PageTitle
          title={this.props.title}
          exstra={
            <Button type="primary">
              <Link to="/sponsors/new">Nyt sponsorat</Link>
            </Button>
          }
        />
        <Table
          loading={this.state.loading}
          rowKey="id"
          columns={columns}
          dataSource={this.state.sponsors}
          onChange={this.onChange}
          className="table-card"
        />
      </>
    )
  }
}

export default Sponsors

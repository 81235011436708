import React from "react"
import { Card, Row, Col, Icon, Input, Button } from "antd"
import ExtContactSelect from "../common/extContactSelect"

const ResponsablesExternal = ({ form }) => {
  const { getFieldValue, getFieldDecorator } = form
  const init = getFieldValue("initResponsablesExternal")
  const initital = Array.isArray(init) || init.length ? init : []
  const initKeys = Object.keys(initital).map(Number)

  getFieldDecorator("exResponsableKeys", {
    initialValue: initKeys.length > 0 ? initKeys : []
  })
  const exResponsableKeys = getFieldValue("exResponsableKeys")
  const providerId = getFieldValue("providerId")

  const exResponsableItems = exResponsableKeys.map(k => (
    <Row
      type="flex"
      align="middle"
      key={`${k}wrapper`}
      className="repeated-fileds-row"
      gutter={15}
    >
      <Col md={{ span: 11 }} xs={{ span: 24 }}>
        <ExtContactSelect
          form={form}
          providerId={providerId}
          initialValue={initital[k] && initital[k].id}
          fieldName={`responsablesExternal[${k}].id`}
        />
        {/* {getFieldDecorator(`responsablesExternal[${k}].id`, {initialValue:initital[k] && initital[k].id })(
          <Select
            showSearch
            onSearch={() => handleEmployeeSearch()}
            placeholder={"Vælg ansvarlig person"}
            optionFilterProp="children"
            onBlur={() => clearEmployeeSearch}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {Array.isArray(data) || data.length ? (
              data.map(d => (
                <Option value={d.id} key={d.id}>
                  {d.name}
                </Option>
              ))
            ) : (
              <Option value={0} key="no-contacts">
                Innen kontakts
              </Option>
            )}
          </Select>
        )} */}
      </Col>
      <Col className="col-grow">
        {getFieldDecorator(`responsablesExternal[${k}].responsibility`, {
          initialValue: initital[k] && initital[k].responsibility
        })(<Input placeholder="Ansvarområde" />)}
      </Col>
      <Col className="delete-repeated-btn">
        <Icon
          className="dynamic-delete-button"
          type="minus-circle-o"
          onClick={() => removeResponableEx(form, k)}
        />
      </Col>
    </Row>
  ))

  return (
    <Card
      type="inner"
      title="Ansvarlig list"
      className="styled-inner-card responsable-card"
    >
      {exResponsableItems}
      <Button type="dashed" onClick={() => addResponsableEx(form)}>
        <Icon type="plus" /> Tilføj ansvarlig
      </Button>
    </Card>
  )
}
const addResponsableEx = form => {
  const exResponsableKeys = form.getFieldValue("exResponsableKeys")
  let newKey = exResponsableKeys.length
  while (exResponsableKeys.includes(newKey)) {
    newKey++
  }
  const nextExResponsableKeys = exResponsableKeys.concat(newKey)
  form.setFieldsValue({ exResponsableKeys: nextExResponsableKeys })
}

const removeResponableEx = (form, k) => {
  const exResponsableKeys = form.getFieldValue("exResponsableKeys")
  form.setFieldsValue({
    exResponsableKeys: exResponsableKeys.filter(key => key !== k)
  })

  const initResponsablesExternal = form.getFieldValue(
    "initResponsablesExternal"
  )

  if (initResponsablesExternal[k])
    form.setFieldsValue({
      initResponsablesExternal: initResponsablesExternal.filter(
        (item, i) => i !== k
      )
    })
}

/* const handleEmployeeSearch = value => {
  global.log("Search will be activated later.", value)
  //this.setState({employeeSearchValue: value})
}
const clearEmployeeSearch = () => {
  //global.log("Search cleared")
  //this.setState({employeeSearchValue: ""})
} */

export default ResponsablesExternal

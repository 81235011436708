import React from "react"
import { Link } from "react-router-dom"
import { Avatar, Icon, Dropdown, Menu } from "antd"
import { stringToColor } from "../utils/stringToColor"
import { getInitials } from "../utils/getUserInitials"

const UserMenu = ({ user }) => {
  const userColor = stringToColor(user.given_name || "Uden navn")
  const userInitials = user.initials
    ? user.initials
    : getInitials(user.given_name || "Uden navn")
  const userMenuItems = (
    <Menu className="user-menu" theme="dark">
      <Menu.Item>
        <Link disabled rel="noopener noreferrer" to="#Min-Konto" key="1">
          <span>Min konto</span>
          <Icon type="setting" />
        </Link>
      </Menu.Item>

      <Menu.Item>
        <Link rel="noopener noreferrer" to="/logout" key="2">
          <span>Logud</span>
          <Icon type="logout" />
        </Link>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown
      overlay={userMenuItems}
      placement="bottomRight"
      trigger={["click", "hover"]}
    >
      <Avatar
        size="large"
        className="avatar-comp"
        style={{ backgroundColor: userColor }}
      >
        {userInitials}
      </Avatar>
    </Dropdown>
  )
}

export default UserMenu

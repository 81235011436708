import React, { Component } from "react"
import {
  Form,
  message,
  Row,
  Col,
  Select,
  Card,
  Spin,
  Input,
  TreeSelect
} from "antd"
import {
  getCompanies,
  saveCompany,
  getCompany,
  getGroups
} from "../services/companyService"
import { getCountries } from "../services/countryService"
import { mapTreeNode } from "../utils/mapTreeNode"
import FormActions from "../components/common/formActions"
import AddressSet from "../components/common/addressSet"
import PageTitle from "../components/common/PageTitle"
import VatNrField from "../components/common/vatNrField"

const { Option } = Select

class DepartmentsForm extends Component {
  state = {
    companies: [],
    groups: [],
    countries: [],
    loading: false
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: companies } = await getCompanies()
    const { data: groups } = await getGroups()
    const { data: countries } = await getCountries()
    this.setState({
      companies,
      groups,
      countries
    })
    await this.populateCompany()
    this.setState({ loading: false })
    // global.log("Mounted !", companies)
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ loading: true })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        // global.log("Received values of form: ", values)
        await saveCompany(values)
        this.setState({ loading: false })
        message.success("Afdeleing er gemt", 3)
        this.props.history.push("/departments")
      } else {
        this.setState({ loading: false })
      }
    })
  }

  async populateCompany() {
    const { id: companyId } = this.props.match.params

    try {
      if (companyId === "new") return
      const { data: company } = await getCompany(companyId)
      const { form } = this.props

      form.setFieldsValue({
        id: company.id,
        name: company.name,
        parentId: company.parentId,
        groupIds: company.groupIds,
        departmentNumber: company.departmentNumber,
        vatNo: company.vatNo,
        streetName: company.streetName,
        streetNumber: company.streetNumber,
        zipCode: company.zipCode,
        city: company.city,
        countryId: company.countryId,
        phone: company.phone,
        email: company.email
      })
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found")
    }
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { companies, countries, groups, loading } = this.state

    getFieldDecorator("id", { initialValue: 0 })
    const companyId = getFieldValue("id")
    const companyName = getFieldValue("name")

    return (
      <>
        <PageTitle title={this.props.title} />
        <Card
          title={
            !companyName || companyName === "" ? "Ny Afdeling" : companyName
          }
          className="card"
          bordered={false}
          actions={[
            <FormActions
              key={1}
              onSubmit={this.handleSubmit}
              cancelLink="/departments"
            />
          ]}
        >
          {loading ? (
            <div className="spin-card-overlay">
              <Spin />
            </div>
          ) : null}
          <Form onSubmit={this.handleSubmit} colon={false}>
            <Row gutter={15}>
              <Col span={8}>
                <VatNrField form={form} />
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={{ span: 12 }} xs={{ span: 16 }}>
                <Form.Item label="Afdeling Navn">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter department Name!"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col md={{ span: 6 }} xs={{ span: 8 }}>
                <Form.Item label="Afdeling Nr.">
                  {getFieldDecorator("departmentNumber")(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item label="Afdeling under">
                  {getFieldDecorator("parentId")(
                    <TreeSelect
                      showSearch
                      treeNodeFilterProp="title"
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto"
                      }}
                      placeholder="Vælg afdeling"
                      // notFoundContent=""
                      allowClear
                    >
                      {mapTreeNode(companies, Number(companyId))}
                    </TreeSelect>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Grupper">
                  {getFieldDecorator("groupIds")(
                    <Select
                      mode="multiple"
                      placeholder="Vælg en eller flere grupper"
                      style={{ width: "100%" }}
                    >
                      {groups.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <div className="form-group-title">Adresse</div>
                <AddressSet
                  getFieldDecorator={getFieldDecorator}
                  countries={countries}
                />
              </Col>
              <Col span={12}>
                <div className="form-group-title">Kontakt info</div>
                <Form.Item>
                  {getFieldDecorator("email")(<Input placeholder="Email" />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("phone")(
                    <Input placeholder="Telefon nr." />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    )
  }
}
export default Form.create()(DepartmentsForm)

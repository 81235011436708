import React from "react"
import { Form, Row, Col, Icon, Input } from "antd"
import AddressSet from "./common/addressSet"
import VatNrField from "./common/vatNrField"
import LocationItem from "./formFields/locationItem"
import ExtContactItem from "./formFields/extContactItem"
import RepeatedFormCollapse from "./repeatedComponents/repeatedFormCollapse"

const ProviderFormFields = ({ form }) => {
  const { getFieldDecorator, getFieldValue } = form
  getFieldDecorator("id", { initialValue: 0 })
  getFieldDecorator("initialLocations", { initialValue: [] })
  getFieldDecorator("initialContacts", { initialValue: [] })
  const initialLocations = getFieldValue("initialLocations")
  const initialContacts = getFieldValue("initialContacts")

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <VatNrField form={form} />
        </Col>
        <Col span={24}>
          <Form.Item label="Navn">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Påkravet Feltet"
                }
              ]
            })(<Input placeholder="Navn" />)}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <div className="form-group-title">Kontakt info</div>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item>
            {getFieldDecorator("email")(
              <Input prefix={<Icon type="mail" />} placeholder="E-mail" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("phone")(
              <Input prefix={<Icon type="phone" />} placeholder="Phone" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("website")(
              <Input prefix={<Icon type="global" />} placeholder="Hjemmeside" />
            )}
          </Form.Item>
        </Col>
        <Col lg={12} sm={24}>
          <RepeatedFormCollapse
            form={form}
            title="Kontaktpersoner"
            initialValue={initialContacts}
            parentName="contacts"
            placeHolder="Ny kontaktperson"
          >
            <ExtContactItem />
          </RepeatedFormCollapse>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <div className="form-group-title">Adresse</div>
        </Col>
        <Col lg={12} sm={24}>
          <AddressSet getFieldDecorator={getFieldDecorator} />
        </Col>
        <Col lg={12} sm={24}>
          <RepeatedFormCollapse
            form={form}
            title="Lokationer"
            initialValue={initialLocations}
            parentName="locations"
            placeHolder="Ny Lokation"
          >
            <LocationItem />
          </RepeatedFormCollapse>
        </Col>
      </Row>
    </>
  )
}

export default ProviderFormFields

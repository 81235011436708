export function formatCurrency(value) {
  const list = `${value}`.split(",")
  const a = list[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  const b = list.length > 1 ? `,${list[1].slice(0, 2)}` : ""
  const result = a + b
  return result
}

export function formatCurrencyForJSON(value, insertDot = false) {
  let result = `${value}`.replace(/(\.*)/g, "")
  result = result.replace(/,/g, ".")
  const { length } = result
  result = insertDot
    ? `${result.slice(0, length - 1)}.${result.slice(length - 1, length)}`
    : result
  return parseFloat(result)
}

export function formatCurrencyFromJSON(value) {
  const list = `${value}`.split(".")
  const a = list[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  const b = list.length > 1 ? `,${list[1].slice(0, 2)}` : ""
  return a + b
}

export function unFormatCurrency(value) {
  return `${value}`.replace(/(\.*)/g, "")
}
export const priceReg = /^(0|[1-9][0-9]*)(,[0-9]*)?$/
// .replace(/(^|,)0+(?!$)/g, "0")

import React from "react"
import moment from "moment"
import {
  DatePicker,
  Form,
  Row,
  Col,
  Icon,
  TimePicker,
  Input,
  Card,
  Button
} from "antd"

const { RangePicker } = DatePicker

const EventDates = ({ form }) => {
  const { getFieldValue, getFieldDecorator } = form
  const init = getFieldValue("initDates")
  const initital = Array.isArray(init) || init.length ? init : []
  const initKeys = Object.keys(initital).map(Number)

  getFieldDecorator("dateKeys", {
    initialValue: initKeys.length > 0 ? initKeys : [0]
  })

  const dateKeys = getFieldValue("dateKeys")
  const dateFormat = "DD-MM-YYYY"
  const timeFormat = "HH:mm"

  const dateItems = dateKeys.map(k => (
    <Row
      type="flex"
      align="top"
      key={`${k}wrapper`}
      className="repeated-fileds-row"
      gutter={12}
    >
      <Col className="date-name-wp">
        {getFieldDecorator(`dates[${k}].id`, {
          initialValue: initital[k] ? initital[k].id : 0
        })(<Input type="hidden" />)}
        <Form.Item layout="inline">
          {getFieldDecorator(`dates[${k}].name`, {
            initialValue: initital[k] ? initital[k].name : ""
          })(<Input placeholder="Dato navn" />)}
        </Form.Item>
      </Col>
      <Col className="date-picker-wp">
        <Form.Item>
          {getFieldDecorator(`dates[${k}].dateRange`, {
            initialValue: initital[k]
              ? [
                  initital[k].start &&
                    moment(initital[k].start, moment.ISO_8601),
                  initital[k].end && moment(initital[k].end, moment.ISO_8601)
                ]
              : [],
            // validateTrigger: ["onBlur"],
            rules: [
              {
                required: true,
                message: "Påkrævet felt"
              }
            ]
          })(<RangePicker format={dateFormat} />)}
        </Form.Item>
      </Col>
      <Col className="time-picker-wp">
        <Form.Item>
          {getFieldDecorator(`dates[${k}].startTime`, {
            initialValue:
              initital[k] && moment(initital[k].start, moment.ISO_8601)
          })(<TimePicker format={timeFormat} />)}
        </Form.Item>
      </Col>
      <Col className="time-picker-wp">
        <Form.Item>
          {getFieldDecorator(`dates[${k}].endTime`, {
            initialValue:
              initital[k] && moment(initital[k].end, moment.ISO_8601)
          })(<TimePicker format={timeFormat} />)}
        </Form.Item>
      </Col>
      <Col className="delete-repeated-btn">
        {dateKeys.length > 1 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            disabled={dateKeys.length === 1}
            onClick={() => removeDate(k, form)}
          />
        ) : null}
      </Col>
    </Row>
  ))

  return (
    <Card type="inner" title="Datoer" className="my-inner-card">
      {dateItems}
      <Button type="dashed" onClick={() => addDate(form)}>
        <Icon type="plus" /> Tilføj dato
      </Button>
    </Card>
  )
}
const addDate = form => {
  const dateKeys = form.getFieldValue("dateKeys")
  let newKey = dateKeys.length
  while (dateKeys.includes(newKey)) {
    newKey++
  }
  const nextDateKeys = dateKeys.concat(newKey)
  form.setFieldsValue({ dateKeys: nextDateKeys })
  global.log(form.getFieldValue("dateKeys"))
}

const removeDate = (k, form) => {
  const dateKeys = form.getFieldValue("dateKeys")
  form.setFieldsValue({ dateKeys: dateKeys.filter(key => key !== k) })

  const initDates = form.getFieldValue("initDates")

  if (initDates[k])
    form.setFieldsValue({ initDates: initDates.filter((item, i) => i !== k) })
}

export default EventDates

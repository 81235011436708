import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/Event`

function eventUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getEvents() {
  return http.get(`${apiEndpoint}/List`)
}

export function getEvent(eventId) {
  return http.get(eventUrl(eventId))
}

export function saveEvent(event) {
  if (event.id !== 0) return http.put(apiEndpoint, event)

  return http.post(apiEndpoint, event)
}

export function deleteEvent(eventId) {
  return http.delete(eventUrl(eventId))
}

import React from "react"
import { Icon, Button } from "antd"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

const DraggableList = ({ list, onDragEnd, onRemove, onPreview }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`${
              snapshot.isDraggingOver ? "isDraggingOver" : ""
            } droppable-area`}
          >
            {list.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={
                      snapshot.isDragging
                        ? "is-dragging draggable-item"
                        : "draggable-item"
                    }
                  >
                    {item.isImage ? (
                      <div className="draggable-item-img">
                        <img src={item.path} alt={item.name} />
                        <Button
                          type="link"
                          className="draggable-item-preview-btn"
                          onClick={() => onPreview(item)}
                        >
                          <Icon type="eye" />
                        </Button>
                      </div>
                    ) : (
                      <div className="draggable-item-img">
                        <Icon type="file" />
                      </div>
                    )}
                    <div className="draggable-item-name">{item.name}</div>
                    <div className="draggable-item-delete-btn">
                      <Icon type="delete" onClick={() => onRemove(item.id)} />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default DraggableList

import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/Category`

function categoryUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getCategories() {
  return http.get(`${apiEndpoint}/List`)
}

export function getCategory(categoryId) {
  return http.get(categoryUrl(categoryId))
}

export function saveCategory(category) {
  if (category.id !== 0) return http.put(apiEndpoint, category)

  return http.post(apiEndpoint, category)
}

export function deleteCategory(categoryId) {
  return http.delete(categoryUrl(categoryId))
}

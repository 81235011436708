import React, { Component } from "react"
import ImageUploader from "./imageUploader"
import {
  getBase64,
  reassignSort,
  reorder,
  isFileImage
} from "../../utils/imageFunctions"

class Uploader extends Component {
  constructor(props) {
    super(props)
    this.state = { previewImage: null }
  }

  // / Upload controllers :
  handleUploadChange = info => {
    if (info.file.status === "uploading") {
      return
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, base64Url => {
        const originalImages = [...this.props.images]

        originalImages.push({
          id: info.file.uid,
          name: info.file.name,
          isImage: isFileImage(info.file),
          path: base64Url
        })
        const images = reassignSort(originalImages)
        this.props.onUploadChange(images)
      })
    }
  }

  handleUploadRemove = fileId => {
    const originalImages = [...this.props.images]
    const filterdImages = originalImages.filter(img => img.id !== fileId)
    const images = reassignSort(filterdImages)
    this.props.onUploadChange(images)
  }

  handleUploadPreview = img => {
    this.setState({ previewImage: img })
  }

  handleCancelPreview = () => {
    this.setState({ previewImage: null })
  }

  handleDragEnd = images => {
    if (!images.destination) {
      return
    }
    const reorderdImages = reorder(
      this.props.images,
      images.source.index,
      images.destination.index
    )
    const imagesWithSort = reassignSort(reorderdImages)
    this.props.onUploadChange(imagesWithSort)
  }

  render() {
    const { images } = this.props
    const { previewImage } = this.state
    return (
      <ImageUploader
        onDragEnd={this.handleDragEnd}
        onChange={this.handleUploadChange}
        onRemove={this.handleUploadRemove}
        onPreview={this.handleUploadPreview}
        onCancelPreview={this.handleCancelPreview}
        previewImage={previewImage}
        list={images}
      />
    )
  }
}

export default Uploader

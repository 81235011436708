import { useReducer, useEffect } from "react"
import http from "../services/httpService"

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      }
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true
      }
    default:
      throw new Error()
  }
}

export function useFetch(url, initialData) {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData
  })
  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" })
      try {
        const result = await http.get(url)
        if (!didCancel) {
          dispatch({
            type: "FETCH_SUCCESS",
            payload: result.data
          })
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: "FETCH_FAILURE" })
        }
      }
    }
    fetchData()
    return () => {
      didCancel = true
    }
  }, [url])
  return [state, dispatch]
}

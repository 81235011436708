import React, { Component } from "react"
import { Form, message, Icon, Spin, Card, Steps } from "antd"
import moment from "moment"
import { getEmployees } from "../services/employeeService"
import { saveSponsor, getSponsor } from "../services/sponsorService"
import Uploader from "../components/common/uploader"
import ResponsablesExternal from "../components/sponsorFormSteps/responsablesExternal"
import ProviderStep from "../components/sponsorFormSteps/providerStep"
import SponsorshipTickets from "../components/sponsorFormSteps/sponsorshipTickets"
import SponsorshipEvents from "../components/sponsorFormSteps/sponsorshipEvents"
import { getDate } from "../utils/dateFunc"
import { getTicketTypes } from "../services/ticketTypeService"
import { getTicketApprovals } from "../services/ticketApprovalService"
import { getProviderContacts } from "../services/providerContactsService"
import FormActions from "../components/common/formActions"
import PageTitle from "../components/common/PageTitle"

const { Step } = Steps

class SponsorForm extends Component {
  state = {
    current: 0,
    loading: false,
    providerContacts: [],
    files: [],
    employees: [],
    ticketTypes: [],
    ticketApprovals: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: employees } = await getEmployees()
    const { data: ticketTypes } = await getTicketTypes()
    const { data: ticketApprovals } = await getTicketApprovals()
    const { hash } = this.props.location
    const currentStepFromUrl = Number(hash[hash.length - 1])
    const current =
      currentStepFromUrl >= 0 && currentStepFromUrl < 5 ? currentStepFromUrl : 0

    this.checkAndPopulate(this.props.match.params.id)

    this.setState({
      loading: false,
      employees,
      current,
      ticketTypes,
      ticketApprovals
    })
  }

  checkAndPopulate = async id => {
    if (id === "new") return
    try {
      const { data: sponsor } = await getSponsor(id)
      this.populateForm(sponsor)
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found")
    }
  }

  populateForm = sponsor => {
    global.log(sponsor)
    const { providerId } = sponsor
    const providerContacts = providerId
      ? this.fetchProviderContacts(providerId)
      : []
    this.setState({
      sponsorshipEvents: sponsor.sponsorshipEvents,
      files: sponsor.files,
      providerContacts
    })
    this.props.form.setFieldsValue({
      id: sponsor.id,
      name: sponsor.name,
      budget: sponsor.budget,
      providerId,
      employeeId: sponsor.employeeId,
      companyId: sponsor.companyId,
      categoryId: sponsor.categoryId,
      dateRange: [moment(sponsor.dateStart), moment(sponsor.dateStop)],
      reminderOffset: sponsor.reminderOffset,
      initResponsables: sponsor.responsables,
      initResponsablesExternal: sponsor.responsablesExternal,
      initSponsorshipTickets: sponsor.sponsorshipTickets
    })
  }

  onStepChange = current => {
    this.validateAndSave()
    this.setState({ current })
  }

  handleSubmit = async () => {
    this.setState({ loading: true })
    await this.validateAndSave()
    /* Object.keys(formError).length === 0 || loading
      ? this.props.history.push("/sponsors")
      : this.setState({loading: false}) */
  }

  handleProviderChange = value => this.fetchProviderContacts(value)

  fetchProviderContacts = async providerId => {
    const { data: providerContacts } = await getProviderContacts(providerId)
    this.setState({ providerContacts })
  }

  handleUpload = files => this.setState({ files })

  async validateAndSave() {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { sponsorshipEvents, current, files } = this.state
        const newSponsor = {
          id: values.id,
          name: values.name,
          budget: values.budget,
          companyId: values.companyId,
          files,
          categoryId: values.categoryId,
          employeeId: values.employeeId,
          providerId: values.providerId,
          reminderOffset: values.reminderOffset,
          responsables:
            current === 0 ? values.responsables : values.initResponsables,
          sponsorshipEvents:
            current === 2 ? values.sponsorshipEvents : sponsorshipEvents,
          responsablesExternal:
            current === 3
              ? values.responsablesExternal
              : values.initResponsablesExternal,
          sponsorshipTickets:
            current === 1
              ? values.sponsorshipTickets
              : values.initSponsorshipTickets
        }
        if (Array.isArray(values.dateRange)) {
          newSponsor.dateStart = getDate(values.dateRange[0])
          newSponsor.dateStop = getDate(values.dateRange[1])
        }

        // global.log("Received values of form: ", values)
        global.log("To Send values of form: ", newSponsor)
        const response = await saveSponsor(newSponsor)
        if (response.data) this.populateForm(response.data)
        else global.log(response.data)
        this.setState({ loading: false })
        message.success("Sponsoratet er gemt", 2)
      }
    })
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      loading,
      employees,
      files,
      ticketTypes,
      ticketApprovals,
      current,
      providerContacts,
      sponsorshipEvents
    } = this.state
    const contractName = getFieldValue("name")

    getFieldDecorator("id", { initialValue: 0 })
    getFieldDecorator("companyId", { initialValue: 0 })
    getFieldDecorator("initResponsables", { initialValue: [] })
    getFieldDecorator("initResponsablesExternal", { initialValue: [] })
    getFieldDecorator("initSponsorshipTickets", { initialValue: [] })

    const steps = [
      {
        title: "Kontraktinfo",
        icon: <Icon type="idcard" />,
        content: (
          <ProviderStep
            form={form}
            employees={employees}
            onProviderChange={this.handleProviderChange}
          />
        )
      },
      {
        title: "Indhold",
        icon: <Icon type="tags" />,
        content: (
          <SponsorshipTickets
            form={form}
            ticketTypes={ticketTypes}
            ticketApprovals={ticketApprovals}
          />
        )
      },
      {
        title: "Detaljer",
        icon: <Icon type="file-image" />,
        content: (
          <SponsorshipEvents
            form={form}
            init={sponsorshipEvents}
            ticketApprovals={ticketApprovals}
          />
        )
      },
      {
        title: "Eksterne ansvarlige",
        icon: <Icon type="contacts" />,
        content: <ResponsablesExternal data={providerContacts} form={form} />
      },
      {
        title: "Filer",
        icon: <Icon type="file-image" />,
        content: <Uploader onUploadChange={this.handleUpload} images={files} />
      }
    ]

    return (
      <>
        <PageTitle title={this.props.title} />
        <Card
          title={
            !contractName || contractName === ""
              ? "Kontrakt navn"
              : contractName
          }
          className="card no-padding"
          bordered={false}
          actions={[
            <FormActions
              key={1}
              onSubmit={this.handleSubmit}
              cancelLink="/sponsors"
            />
          ]}
        >
          {loading ? (
            <div className="spin-card-overlay">
              <Spin />
            </div>
          ) : null}

          <Form colon={false}>
            <Steps
              type="navigation"
              size="small"
              onChange={this.onStepChange}
              current={current}
              className="step-style"
            >
              {steps.map(item => (
                <Step icon={item.icon} key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
          </Form>
        </Card>
      </>
    )
  }
}
export default Form.create()(SponsorForm)

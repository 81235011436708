import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/Sponsorship`

function sponsorUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getSponsors() {
  return http.get(`${apiEndpoint}/List`)
}

export function getSponsor(sponsorId) {
  return http.get(sponsorUrl(sponsorId))
}

export function saveSponsor(sponsor) {
  if (sponsor.id !== 0) return http.put(apiEndpoint, sponsor)

  return http.post(apiEndpoint, sponsor)
}

export function deleteSponsor(sponsorId) {
  return http.delete(sponsorUrl(sponsorId))
}

import React, { Component } from "react"
import { Form } from "antd"
import FormActions from "./common/formActions"
import { saveProvider } from "../services/providerService"
import ProviderFormFields from "./providerFormFields"

class ProviderFormDrawer extends Component {
  componentDidMount() {
    // global.log(this.props.providerId)
  }

  handleSubmit = () => {
    const { form, onAdded } = this.props
    const { validateFields } = form

    validateFields(async (err, values) => {
      if (!err) {
        global.log("Form values:", values)
        try {
          const { data } = await saveProvider(values)
          global.log("Server response:", data)
          onAdded({
            id: data.id,
            name: data.name
          })
        } catch (error) {
          global.log(error.response)
        }
      }
    })
  }

  render() {
    const { form, onCancel } = this.props

    return (
      <>
        <h2 className="drawer-title">Tilføj Udbyder</h2>
        <Form colon={false} className="provider-form-drawer">
          <ProviderFormFields form={form} />
        </Form>
        <div className="drawer-footer">
          <FormActions onSubmit={this.handleSubmit} onCancel={onCancel} />
        </div>
      </>
    )
  }
}

export default Form.create()(ProviderFormDrawer)

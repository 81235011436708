import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { ConfigProvider } from "antd"
import daDK from "antd/es/locale/da_DK"
import "moment/locale/da"
import App from "./App"
import registerServiceWorker from "./registerServiceWorker"
import { UserProvider } from "./contexts/userContext"

global.log = (...msgs) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === "development") console.log(...msgs)
}
// const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches

ReactDOM.render(
  <ConfigProvider locale={daDK}>
    <BrowserRouter>
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById("root")
)
registerServiceWorker()

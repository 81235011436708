import React from "react"
import { Drawer } from "antd"
import { useDrawerState, useDrawerDispatch } from "../contexts/drawerContext"
import ProviderFormDrawer from "./providerFormDrawer"
import LocationFormDrawer from "./locationFormDrawer"
import ExtContactFormDrawer from "./extContactFormDrawer"

const DrawerHandler = () => {
  const state = useDrawerState()
  const dispatch = useDrawerDispatch()

  const drawerContent = () => {
    switch (state.type) {
      case "provider":
        return (
          <ProviderFormDrawer
            providerId={state.id}
            onCancel={() => dispatch({ type: "CANCEL" })}
            onAdded={item =>
              dispatch({
                type: "DONE",
                payload: item
              })
            }
          />
        )

      case "location":
        return (
          <LocationFormDrawer
            providerId={state.id}
            onCancel={() => dispatch({ type: "CANCEL" })}
            onAdded={item =>
              dispatch({
                type: "DONE",
                payload: item
              })
            }
          />
        )

      case "contact":
        return (
          <ExtContactFormDrawer
            providerId={state.id}
            onCancel={() => dispatch({ type: "CANCEL" })}
            onAdded={item =>
              dispatch({
                type: "DONE",
                payload: item
              })
            }
          />
        )

      default:
        return null
    }
  }

  return (
    <Drawer
      placement="right"
      width={560}
      onClose={() => dispatch({ type: "CANCEL" })}
      visible={state.drawerVisible}
    >
      {drawerContent()}
    </Drawer>
  )
}

export default DrawerHandler

import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/Company`

function companyUrl(id) {
  return `${apiEndpoint}/${id}`
}

function groupUrl(id) {
  return `${apiEndpoint}/Group/${id}`
}

export function getCompanies() {
  return http.get(`${apiEndpoint}/List`)
}

export function getCompany(companyId) {
  return http.get(companyUrl(companyId))
}

export function getGroups() {
  return http.get(`${apiEndpoint}/GroupList`)
}

export function getGroup(groupId) {
  return http.get(groupUrl(groupId))
}

export function saveCompany(company) {
  if (company.id !== 0) return http.put(apiEndpoint, company)

  return http.post(apiEndpoint, company)
}

export function deleteCompany(companyId) {
  return http.delete(companyUrl(companyId))
}

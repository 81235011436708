import React from "react"
import { Row, Form, Col, Select, Input } from "antd"
import { PropTypes } from "prop-types"
import { countriesUrl } from "../../services/countryService"
import { useFetch } from "../../utils/useFetch"

const { Option } = Select

const AddressSet = ({
  getFieldDecorator,
  parentName,
  initial,
  autoComplete
}) => {
  const pn = parentName && parentName.length ? `${parentName}.` : ""
  const [{ isLoading, isError, data: countries }] = useFetch(countriesUrl, [])
  return (
    <>
      <Row gutter={4}>
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${pn}streetName`, {
              initialValue: initial && initial.streetName
            })(
              <Input
                placeholder="Gade"
                autoComplete={!autoComplete ? "new-pass" : ""}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator(`${pn}streetNumber`, {
              initialValue: initial && initial.streetNumber
            })(
              <Input
                placeholder="Gade nr."
                autoComplete={!autoComplete ? "new-pass" : ""}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={4}>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator(`${pn}zipCode`, {
              initialValue: initial && initial.zipCode
            })(
              <Input
                placeholder="Postnummer"
                autoComplete={!autoComplete ? "new-pass" : ""}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`${pn}city`, {
              initialValue: initial && initial.city
            })(
              <Input
                placeholder="By"
                autoComplete={!autoComplete ? "new-pass" : ""}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          {!isError && (
            <Form.Item>
              {getFieldDecorator(`${pn}countryId`, {
                initialValue:
                  initial && initial.countryId
                    ? initial.countryId
                    : countries[0] && countries[0].id,
                rules: [
                  {
                    required: true,
                    message: "Påkravet feltet"
                  }
                ]
              })(
                <Select
                  placeholder="Vælg et land"
                  style={{ width: "100%" }}
                  loading={isLoading}
                >
                  {countries.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
        </Col>
      </Row>
    </>
  )
}

AddressSet.defaultProps = {
  parentName: "",
  autoComplete: true,
  initial: undefined
}

AddressSet.propsType = {
  autoComplete: PropTypes.bool,
  parentName: PropTypes.string,
  initial: PropTypes.object,
  getFieldDecorator: PropTypes.func
}

export default AddressSet

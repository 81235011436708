import React from "react"
import { Layout } from "antd"

const { Footer: AntFooter } = Layout

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <AntFooter title={`v${global.appVersion}`} className="app-footer">
      powered by OneLounge &copy;2018-{currentYear}
    </AntFooter>
  )
}

export default Footer

import React from "react"
import { Row, Col } from "antd"

const PageTitle = ({ title, exstra }) => {
  return (
    title && (
      <Row
        type="flex"
        justify="space-around"
        className="page-title-row"
        align="middle"
      >
        <Col span={12}>
          <h1>{title}</h1>
        </Col>
        <Col span={12} className="content-btn-wp">
          {exstra}
        </Col>
      </Row>
    )
  )
}

export default PageTitle

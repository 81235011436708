import React from "react"
import {
  Form,
  Row,
  Col,
  Empty,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Card
} from "antd"
import { formatDate } from "../../utils/dateFunc"
import PriceInput from "../common/priceInput"

const { Option } = Select

const SponsorshipEvents = ({ form, init, ticketApprovals }) => {
  const { getFieldDecorator } = form
  const mappedEvents = !Array.isArray(init) ? (
    <Empty description="Valg udbyder først.." />
  ) : (
    init.map((event, i) => (
      <Card
        type="inner"
        key={event.id}
        title={event.eventName}
        className="styled-inner-card"
        extra={
          <div className="extra-date">
            Startdato <span>{formatDate(event.eventStart)}</span>
          </div>
        }
      >
        {getFieldDecorator(`sponsorshipEvents[${i}].eventId`, {
          initialValue: event.eventId
        })(<input type="hidden" />)}
        {getFieldDecorator(`sponsorshipEvents[${i}].eventName`, {
          initialValue: event.eventName
        })(<input type="hidden" />)}
        {getFieldDecorator(`sponsorshipEvents[${i}].eventStart`, {
          initialValue: event.eventStart
        })(<input type="hidden" />)}
        <Row
          gutter={10}
          className="sponsor-event-title"
          type="flex"
          align="middle"
        >
          <Col span={4}>Billet navn</Col>
          <Col span={4}>Billetbestillings URL</Col>
          <Col span={2}>Selvhenter</Col>
          <Col span={3}>Godkendelses-handling</Col>
          <Col span={5}>Bestillingsfrist</Col>
          <Col span={2}>Maks antal billetter</Col>
          <Col span={3}>Pris</Col>
          <Col span={1}>Vis pris</Col>
        </Row>
        {mappedTicketEventDetails(
          i,
          event.ticketEventDetails,
          ticketApprovals,
          form
        )}
      </Card>
    ))
  )

  return (
    <Card bordered={false} className="no-padding">
      {mappedEvents}
    </Card>
  )
}

const mappedTicketEventDetails = (
  i,
  ticketEventDetails,
  ticketApprovals,
  form
) => {
  const { getFieldDecorator } = form
  return ticketEventDetails.map((item, index) => (
    <Row
      gutter={15}
      key={item.id}
      className="formItem-no-margin sponsor-event-fields"
      type="flex"
      align="middle"
    >
      <Col span={4}>
        <h4>{item.name}</h4>
        {getFieldDecorator(
          `sponsorshipEvents[${i}].ticketEventDetails[${index}].id`,
          { initialValue: item.id }
        )(<input type="hidden" />)}
        {getFieldDecorator(
          `sponsorshipEvents[${i}].ticketEventDetails[${index}].sponsorshipTicketId`,
          { initialValue: item.sponsorshipTicketId }
        )(<input type="hidden" />)}
        {getFieldDecorator(
          `sponsorshipEvents[${i}].ticketEventDetails[${index}].eventPeriodId`,
          { initialValue: item.eventPeriodId }
        )(<input type="hidden" />)}
      </Col>
      <Col span={4}>
        <Form.Item>
          {getFieldDecorator(
            `sponsorshipEvents[${i}].ticketEventDetails[${index}].urlEmail`,
            { initialValue: item.urlEmail }
          )(<Input />)}
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item className="text-center">
          {getFieldDecorator(
            `sponsorshipEvents[${i}].ticketEventDetails[${index}].urlRedirect`,
            {
              initialValue: item.urlRedirect,
              valuePropName: "checked"
            }
          )(<Checkbox />)}
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item>
          {getFieldDecorator(
            `sponsorshipEvents[${i}].ticketEventDetails[${index}].ticketApprovalId`,
            { initialValue: item.ticketApprovalId }
          )(
            <Select>
              {ticketApprovals.map(d => (
                <Option value={d.id} key={d.id}>
                  {d.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item
          style={{
            display: "inline-block",
            width: "calc(50% - 5px)",
            marginRight: 10
          }}
        >
          {getFieldDecorator(
            `sponsorshipEvents[${i}].ticketEventDetails[${index}].orderOffsetValue`,
            { initialValue: item.orderOffsetValue }
          )(<InputNumber min={0} style={{ width: "100%" }} />)}
        </Form.Item>
        <Form.Item
          style={{
            display: "inline-block",
            width: "calc(50% - 5px)"
          }}
        >
          {getFieldDecorator(
            `sponsorshipEvents[${i}].ticketEventDetails[${index}].OrderOffsetType`,
            { initialValue: item.orderOffsetType }
          )(
            <Select>
              <Option value="0" key="day">
                dag(e)
              </Option>
              <Option value="1" key="weeks">
                uge(r)
              </Option>
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item>
          {getFieldDecorator(
            `sponsorshipEvents[${i}].ticketEventDetails[${index}].maxQuantity`,
            { initialValue: item.maxQuantity }
          )(<InputNumber min={0} style={{ width: "100%" }} />)}
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item>
          <PriceInput
            fieldName={`sponsorshipEvents[${i}].ticketEventDetails[${index}].price`}
            form={form}
            initialValue={item.price}
          />
        </Form.Item>
      </Col>
      <Col span={1}>
        <Form.Item>
          {getFieldDecorator(
            `sponsorshipEvents[${i}].ticketEventDetails[${index}].priceVisible`,
            {
              initialValue: item.priceVisible,
              valuePropName: "checked"
            }
          )(<Checkbox />)}
        </Form.Item>
      </Col>
    </Row>
  ))
}
export default SponsorshipEvents

import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Table, Popconfirm, Icon, message, Tag, Button } from "antd"
import { getEvents, deleteEvent } from "../services/eventService"
import PageTitle from "../components/common/PageTitle"
import { formatDate } from "../utils/dateFunc"

class Events extends Component {
  state = {
    events: [],
    loading: false
  }
  /* onChange = (pagination, filters, sorter) => {
    global.log("params", pagination, filters, sorter)
  }; */

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: events } = await getEvents()
    this.setState({
      events,
      loading: false
    })
    global.log("Events List", events)
  }

  handleDelete = async event => {
    const originalEvents = [...this.state.events]
    const { id } = event
    const events = originalEvents.filter(e => e.id !== event.id)
    global.log(id, events)
    this.setState({
      loading: true,
      events
    })

    try {
      await deleteEvent(id)
      this.setState({ loading: false })
      message.success("Deleted")
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        message.error("Not deleted")

      this.setState({
        events: originalEvents,
        loading: false
      })
    }
  }
  /*
  formatDate = dateISO => {
    const dateObj = new Date(dateISO),
      date = dateObj.getDate(),
      month = dateObj.getMonth() + 1,
      year = dateObj.getFullYear()

    return date + "/" + month + "/" + year
  }; */

  showMore = index => {
    global.log(index)
  }

  render() {
    const { loading, events } = this.state
    const columns = [
      {
        title: "Navn",
        dataIndex: "name",
        // defaultSortOrder: "descend",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
        render: (text, record) => (
          <Link to={`/events/${record.id}`}>{text}</Link>
        )
      },
      {
        title: "Kategori",
        dataIndex: "categoryName",
        key: "categoryName"
      },
      {
        title: "Startdato",
        className: "text-right",
        dataIndex: "dateStart",
        key: "dateStart",
        render: text => text && formatDate(text)
      },
      {
        title: "Slutdato",
        dataIndex: "dateStop",
        className: "text-right",
        key: "dateStop",
        render: text => text && formatDate(text)
      },
      {
        title: "Tags",
        key: "tags",
        dataIndex: "tags",
        render: tags => (
          <span>
            {tags.map(tag => {
              return <Tag key={tag.id}>{tag.name}</Tag>
            })}
          </span>
        )
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        width: 100,
        className: "action-column",
        render: record => (
          <Popconfirm
            title={`Er du sikker på at du vil slette ${record.name} ?`}
            okText="Ja"
            cancelText="Nej"
            onConfirm={() => this.handleDelete(record)}
          >
            <div className="delete-icon">
              <Icon type="delete" />
            </div>
          </Popconfirm>
        )
      }
    ]
    return (
      <>
        <PageTitle
          title={this.props.title}
          exstra={
            <Button type="primary">
              <Link to="/events/new">Nyt Event</Link>
            </Button>
          }
        />
        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          dataSource={events}
          className="table-card"
        />
      </>
    )
  }
}

export default Events

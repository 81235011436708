import React from "react"
import { Empty } from "antd"
import PageTitle from "../components/common/PageTitle"

class Dashboard extends React.Component {
  state = {}

  render() {
    return (
      <>
        <PageTitle title={this.props.title} />
        <div className="empty-page">
          <Empty description="Der er ikke nok data til statistik" />
        </div>
      </>
    )
  }
}

export default Dashboard

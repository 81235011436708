import React from "react"
import { Row, Col, Input } from "antd"
import OrderParticipants from "./orderParticipants"

const OrderParticipantsCompany = ({
  companies,
  getFieldDecorator,
  onSwitch
}) => {
  return companies
    ? companies.map((com, index) => (
        <div
          className={`order-company-item ${index > 0 && "pt-2"}`}
          key={com.id}
        >
          <Row
            type="flex"
            align="bottom"
            className="order-details-row order-company-name"
          >
            <Col className="">
              <h3>
                {getFieldDecorator(`companies[${index}].id`, {
                  initialValue: com.id
                })(<Input type="hidden" />)}
                {com.name}
              </h3>
            </Col>
            <Col className="order-company-type">{com.participantType}</Col>
            {/* <Col className="order-company-link order-switch-col">
          <Button type="link">Slå alle fra</Button>
        </Col> */}
          </Row>
          <OrderParticipants
            onSwitch={onSwitch}
            getFieldDecorator={getFieldDecorator}
            company={com}
            companyKey={index}
          />
        </div>
      ))
    : null
}

export default OrderParticipantsCompany

import React from "react"
import { Table, Button, message, Icon, Popconfirm, Result } from "antd"
import { Link } from "react-router-dom"
import PageTitle from "../components/common/PageTitle"
import { providersUrl, deleteProvider } from "../services/providerService"
import { useFetch } from "../utils/useFetch"

const Providers = ({ title }) => {
  const [{ isLoading, isError, data: providers }, dispatch] = useFetch(
    providersUrl,
    []
  )

  const handleDelete = async provider => {
    const originalProviders = [...providers]
    const { id } = provider
    const newProviders = originalProviders.filter(e => e.id !== id)
    dispatch({
      type: "FETCH_SUCCESS",
      payload: newProviders
    })
    try {
      await deleteProvider(id)
      message.success("Deleted")
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        message.error("Not deleted")

      dispatch({
        type: "FETCH_SUCCESS",
        payload: originalProviders
      })
    }
  }

  const columns = [
    {
      title: "Udbyder",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <Link to={`/providers/${record.id}`}>{text}</Link>
      )
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Telefon nr.",
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      width: 100,
      className: "action-column",
      render: record => (
        <Popconfirm
          title={`Er du sikker på at du vil slette ${record.name} ?`}
          okText="Ja"
          cancelText="Nej"
          onConfirm={() => handleDelete(record)}
        >
          <div className="delete-icon">
            <Icon type="delete" />
          </div>
        </Popconfirm>
      )
    }
  ]

  return (
    <>
      <PageTitle
        title={title}
        exstra={
          <Button type="primary">
            <Link to="/providers/new">Ny udbyder</Link>
          </Button>
        }
      />
      {isError ? (
        <Result
          status="warning"
          title="Der er opstået en teknisk fejl. Prøv igen senere."
        />
      ) : (
        <Table
          className="table-card"
          loading={isLoading}
          columns={columns}
          rowKey="id"
          dataSource={providers}
        />
      )}
    </>
  )
}

export default Providers

import React from "react"
import { Route, Redirect } from "react-router-dom"
// import auth from "../../services/authService"
import { useUser } from "../../contexts/userContext"

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const user = useUser()
  return (
    <Route
      {...rest}
      render={props => {
        if (!user)
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        return Component ? <Component {...props} /> : render(props)
      }}
    />
  )
}

export default ProtectedRoute

import React from "react"
import { Form, Input, Row, Col } from "antd"
import { PropTypes } from "prop-types"
import AddressSet from "../common/addressSet"

const LocationItem = ({ getFieldDecorator, initial, parentName }) => {
  const pn = parentName && parentName.length ? `${parentName}.` : ""
  return (
    <div>
      {getFieldDecorator(`${pn}id`, { initialValue: initial ? initial.id : 0 })(
        <Input type="hidden" />
      )}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item>
            {getFieldDecorator(`${pn}name`, {
              initialValue: initial && initial.name,
              rules: [
                {
                  required: true,
                  message: "Påkravet Feltet"
                }
              ]
            })(
              <Input
                autoFocus
                placeholder="Lokation navn"
                autoComplete="new-password"
                name="LN"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <AddressSet
            autoComplete={false}
            initial={initial}
            parentName={parentName}
            getFieldDecorator={getFieldDecorator}
          />
        </Col>
      </Row>
    </div>
  )
}

LocationItem.defaultProps = {
  parentName: "",
  initial: undefined,
  getFieldValue: () => {},
  setFieldsValue: () => {},
  getFieldDecorator: () => {}
}
LocationItem.propsType = {
  parentName: PropTypes.string,
  initial: PropTypes.object,
  getFieldValue: PropTypes.func,
  setFieldsValue: PropTypes.func,
  getFieldDecorator: PropTypes.func.isRequired
}

export default LocationItem

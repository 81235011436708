import React from "react"
import {
  Form,
  Row,
  Divider,
  Col,
  Icon,
  Checkbox,
  Input,
  InputNumber,
  Button,
  Select,
  Card
} from "antd"
import PriceInput from "../common/priceInput"

const { Option } = Select

const SponsorshipTickets = ({ form, ticketTypes, ticketApprovals }) => {
  const { getFieldValue, getFieldDecorator } = form
  const init = getFieldValue("initSponsorshipTickets")
  const initital = Array.isArray(init) || init.length ? init : []
  const initKeys = Object.keys(initital).map(Number)

  getFieldDecorator("ticketKeys", {
    initialValue: initKeys.length > 0 ? initKeys : []
  })
  const ticketKeys = getFieldValue("ticketKeys")

  const ticketTypesItems = ticketKeys.map(k => (
    <Col lg={{ span: 8 }} md={{ span: 12 }} key={`${k}wrapper`}>
      <Card
        title="Billet"
        className="ticket-type-item"
        bordered={false}
        extra={
          <Button
            shape="circle"
            icon="delete"
            className="delete-icon"
            onClick={() => removeSponsorshipTicket(k, form)}
          />
        }
      >
        {getFieldDecorator(`sponsorshipTickets[${k}].id`, {
          initialValue: initital[k] ? initital[k].id : 0
        })(<Input type="hidden" />)}
        <Form.Item /* label="Billettype" */>
          {getFieldDecorator(`sponsorshipTickets[${k}].ticketTypeId`, {
            initialValue: initital[k] && initital[k].ticketTypeId
          })(
            <Select placeholder="Billettype">
              {ticketTypes.map(d => (
                <Option value={d.id} key={d.id}>
                  {d.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Kort beskrivelse">
          {getFieldDecorator(`sponsorshipTickets[${k}].description`, {
            initialValue: initital[k] ? initital[k].description : ""
          })(
            <Input.TextArea
              autoSize={{
                minRows: 2,
                maxRows: 4
              }}
            />
          )}
        </Form.Item>

        <Form.Item label="Billet E-mail">
          {getFieldDecorator(`sponsorshipTickets[${k}].ticketEmail`, {
            initialValue: initital[k] ? initital[k].ticketEmail : ""
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Godkendelsehandling">
          {getFieldDecorator(`sponsorshipTickets[${k}].ticketApprovalId`, {
            initialValue: initital[k] ? initital[k].ticketApprovalId : ""
          })(
            <Select>
              {ticketApprovals.map(d => (
                <Option value={d.id} key={d.id}>
                  {d.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <div className="checkbox-wp">
          {getFieldDecorator(`sponsorshipTickets[${k}].pool`, {
            initialValue: initital[k] && initital[k].pool,
            valuePropName: "checked"
          })(<Checkbox>Pulje</Checkbox>)}
        </div>
        <Divider dashed />
        <Row gutter={30}>
          <Col span={8}>
            <Form.Item label="Antal">
              {getFieldDecorator(`sponsorshipTickets[${k}].count`, {
                initialValue: initital[k] ? initital[k].count : ""
              })(<InputNumber min={0} style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item label="Pris pr. billet">
              <PriceInput
                fieldName={`sponsorshipTickets[${k}].price`}
                form={form}
                initialValue={initital[k] ? initital[k].price : ""}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Col>
  ))

  return (
    <Card bordered={false} className="no-padding">
      <Row type="flex" align="middle" gutter={10}>
        <Col>
          <Button type="dashed" onClick={() => addSponsorshipTicket(form)}>
            <Icon type="plus" /> Billetter
          </Button>
        </Col>
        <Col>
          <Button
            type="dashed"
            disabled
            onClick={() => addSponsorshipTicket(form)}
          >
            <Icon type="plus" /> LED
          </Button>
        </Col>
      </Row>
      <Row className="ticket-type-wp" type="flex" gutter={30}>
        {ticketTypesItems}
      </Row>
    </Card>
  )
}

const addSponsorshipTicket = form => {
  const ticketKeys = form.getFieldValue("ticketKeys")
  global.log(ticketKeys)
  let newKey = ticketKeys.length
  while (ticketKeys.includes(newKey)) {
    newKey++
  }
  const nextTicketKeys = ticketKeys.concat(newKey)
  form.setFieldsValue({ ticketKeys: nextTicketKeys })
}

const removeSponsorshipTicket = (k, form) => {
  //  const {form} = this.props,
  const ticketKeys = form.getFieldValue("ticketKeys")
  form.setFieldsValue({ ticketKeys: ticketKeys.filter(key => key !== k) })

  const initSponsorshipTickets = form.getFieldValue("initSponsorshipTickets")

  if (initSponsorshipTickets[k])
    form.setFieldsValue({
      initSponsorshipTickets: initSponsorshipTickets.filter(
        (item, i) => i !== k
      )
    })

  // sponsorshipTickets = [...this.state.sponsorshipTickets]

  /* sponsorshipTickets.splice(k, 1)
  this.setState({sponsorshipTickets}) */
}

export default SponsorshipTickets

import React from "react"
import { TreeSelect } from "antd"

const { TreeNode } = TreeSelect

export function mapTreeNode(data, disabledId) {
  return data.map(item => {
    if (item.children) {
      return (
        <TreeNode
          disabled={item.id === disabledId}
          key={item.id}
          value={item.id}
          title={item.name}
        >
          {mapTreeNode(item.children)}
        </TreeNode>
      )
    }
    return (
      <TreeNode
        key={item.id}
        value={item.id}
        title={item.name}
        disabled={item.id === disabledId}
      />
    )
  })
}

import React from "react"
import { Layout } from "antd"
// App Cache Buster
import CacheBuster from "./cacheBuster"
// App Context Handler
import { DrawerProvider } from "./contexts/drawerContext"
import DrawerHandler from "./components/drawerHandler"
import { useUser } from "./contexts/userContext"
// App Routes
import Routes from "./routes/routes"
// App Layout Components
import SideNav from "./layout/sideNav"
import Header from "./layout/header"
import Footer from "./layout/footer"
// App Style
import "./assets/scss/App.scss"

const App = () => {
  const user = useUser()

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload()
        }
        return (
          <Layout className="app-layout">
            {user && <SideNav />}
            <Layout>
              <Header />
              <Layout.Content id="content-wrapper">
                <main className="container main">
                  <DrawerProvider>
                    <Routes />
                    <DrawerHandler />
                  </DrawerProvider>
                </main>
              </Layout.Content>
              <Footer />
            </Layout>
          </Layout>
        )
      }}
    </CacheBuster>
  )
}

export default App

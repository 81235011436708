import React, { Component } from "react"
import { Form, DatePicker, Row, Col, Input, TreeSelect, Select } from "antd"
import { mapTreeNode } from "../../utils/mapTreeNode"
import { getCategories } from "../../services/categoryService"
// import {getProviders} from "../../services/providerService"
import Responsables from "./responsables"
import PriceInput from "../common/priceInput"
import ProviderSelect from "../providerSelect"

const { Option } = Select
const { RangePicker } = DatePicker

class ProviderStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: []
    }
  }

  async componentDidMount() {
    const { data: categories } = await getCategories()

    this.setState({
      categories
    })
  }

  /*   handleEmployeeSearch = value => {
    this.setState({ employeeSearchValue: value })
  }

  clearEmployeeSearch = () => {
    this.setState({ employeeSearchValue: "" })
  } */

  render() {
    const { form, employees } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { categories } = this.state
    const reminderData = [
      {
        value: "1week",
        name: "1 uge"
      },
      {
        value: "2week",
        name: "2 uger"
      },
      {
        value: "1mon",
        name: "1 måned"
      },
      {
        value: "2mon",
        name: "2 måneder"
      }
    ]
    const providerIsSelected = !getFieldValue("providerId")
    return (
      <>
        <Row gutter={15}>
          <Col md={{ span: 12 }}>
            <Form.Item label="Kontraktnavn">
              {getFieldDecorator("name", {
                preserve: true,
                rules: [
                  {
                    required: true,
                    message: "Udfyld venligst feltet"
                  }
                ]
              })(<Input placeholder="Sponsorat navn" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col md={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item label="Udbyder">
              <ProviderSelect form={form} preserve />
            </Form.Item>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item label="Kategori">
              {getFieldDecorator("categoryId", { preserve: true })(
                <TreeSelect
                  disabled={providerIsSelected}
                  showSearch
                  treeNodeFilterProp="title"
                  searchPlaceholder="Søg.."
                  onSearch={this.categoriesSearch}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto"
                  }}
                  placeholder="Please select"
                  allowClear
                >
                  {mapTreeNode(categories, form.getFieldValue("categoryId"))}
                </TreeSelect>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col md={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item label="Sponsorat">
              <PriceInput
                fieldName="budget"
                form={form}
                preserve
                disabled={providerIsSelected}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col md={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item label="Kontraktansvarlig">
              {getFieldDecorator("employeeId", { preserve: true })(
                <Select
                  disabled={providerIsSelected}
                  showSearch
                  // onSearch={this.handleEmployeeSearch}
                  placeholder="Vælg kontraktansvarlig.."
                  optionFilterProp="children"
                  // onBlur={this.clearEmployeeSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {employees.map(d => (
                    <Option value={d.id} key={d.id}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item label="Kontrakt periode">
              {getFieldDecorator(`dateRange`, {
                preserve: true
                /* initialValue: [
                initDateStart && moment(initDateStart, moment.ISO_8601),
                initDateEnd && moment(initDateEnd, moment.ISO_8601),
              ], */
                /* rules: [{required: true,message: "Please input start date or delete this field.",},], */
              })(<RangePicker disabled={providerIsSelected} />)}
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Påmindelse om kontraktudløb">
              {getFieldDecorator(`reminderOffset`, { preserve: true })(
                <Select disabled={providerIsSelected}>
                  {reminderData.map(d => (
                    <Option value={d.value} key={d.value}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col lg={{ span: 16 }} xs={{ span: 24 }}>
            <Responsables
              data={employees}
              disabled={providerIsSelected}
              form={form}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default ProviderStep

import React, { Component } from "react"
import { Form, message, Icon, Row, Select, Col, Card, Spin, Input } from "antd"
import { saveEmployee, getEmployee } from "../services/employeeService"
import { getCountries } from "../services/countryService"
import DepartmentsCheckboxTable from "../components/departmentsCheckboxTable"
import FormActions from "../components/common/formActions"
import PageTitle from "../components/common/PageTitle"

const { Option } = Select

class EmployeeForm extends Component {
  state = {
    loading: false,
    employeeId: "new",
    countries: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { data: countries } = await getCountries()
    const { id: employeeId } = this.props.match.params
    this.setState({
      countries,
      employeeId
    })
    this.populateForm(employeeId)
    this.setState({ loading: false })
  }

  populateForm = async employeeId => {
    if (employeeId === "new") return
    try {
      const { data: employee } = await getEmployee(employeeId)
      this.props.form.setFieldsValue({
        id: employee.id,
        roleId: employee.roleId,
        password: "",
        name: employee.name,
        email: employee.email,
        phone: employee.phone,
        title: employee.title,
        number: employee.number,
        initials: employee.initials,
        countryId: employee.countryId,
        companyIds: employee.companyIds,
        companyLeaderIds: employee.companyLeaderIds
      })
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found")
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ loading: true })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        // global.log("Received values of form: ", values)
        await saveEmployee(values)
        this.setState({ loading: false })
        message.success("Employee er gemt", 3)
        this.props.history.push("/employees")
      } else {
        this.setState({ loading: false })
      }
    })
  }

  handleLeaderCheckbox = recordId => {
    const { setFieldsValue, getFieldValue } = this.props.form
    let companyLeaderIds = getFieldValue("companyLeaderIds")
    if (companyLeaderIds.includes(recordId))
      companyLeaderIds = companyLeaderIds.filter(r => r !== recordId)
    else companyLeaderIds.push(recordId)
    setFieldsValue({ companyLeaderIds })
  }

  handleCompanyCheckbox = recordId => {
    const { setFieldsValue, getFieldValue } = this.props.form
    let companyIds = getFieldValue("companyIds")
    if (companyIds.includes(recordId))
      companyIds = companyIds.filter(r => r !== recordId)
    else companyIds.push(recordId)
    setFieldsValue({ companyIds })
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { loading, countries, employeeId } = this.state
    const employeeName = getFieldValue("name")
    const companyLeaderIds = getFieldValue("companyLeaderIds")
    const companyIds = getFieldValue("companyIds")

    getFieldDecorator("id", { initialValue: 0 })
    getFieldDecorator("companyLeaderIds", { initialValue: [] })
    getFieldDecorator("companyIds", { initialValue: [] })

    return (
      <>
        <PageTitle title={this.props.title} />
        <Card
          title={
            !employeeName || employeeName === ""
              ? "Ny medarbejder"
              : employeeName
          }
          className="card"
          bordered={false}
          actions={[
            <FormActions
              key={1}
              onSubmit={this.handleSubmit}
              cancelLink="/employees"
            />
          ]}
        >
          {loading ? (
            <div className="spin-card-overlay">
              <Spin />
            </div>
          ) : null}
          <Form colon={false}>
            <Row gutter={15}>
              <Col md={{ span: 12 }}>
                <Form.Item label="Navn">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Udfyld venligst feltet"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Title">
                  {getFieldDecorator("title")(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 6 }}>
                <Form.Item label="Initialer">
                  {getFieldDecorator("initials", {
                    getValueFromEvent: e => e.target.value.toUpperCase()
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 6 }}>
                <Form.Item label="Medarbejder nr.">
                  {getFieldDecorator("number")(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Land">
                  {getFieldDecorator("countryId", {
                    initialValue: countries[0] && countries[0].id,
                    rules: [
                      {
                        required: true,
                        message: "Vælg et land"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Vælg et land"
                      style={{ width: "100%" }}
                    >
                      {countries.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={24}>
                <div className="form-group-title">Kontakt &amp; login</div>
              </Col>
              <Col span={12}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "Udfyld venligst feltet med Email"
                      },
                      {
                        required: true,
                        message: "Udfyld venligst feltet"
                      }
                    ]
                  })(<Input placeholder="Email" />)}
                </Form.Item>
                <Form.Item label="Rolle">
                  {getFieldDecorator("roleId", { initialValue: 2 })(
                    <Select style={{ width: "100%" }}>
                      <Option key="2" value={2}>
                        Bruger
                      </Option>
                      <Option key="1" value={1}>
                        Administrator
                      </Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    employeeId === "new" ? "Adgangskode" : "Ny adgangskode"
                  }
                >
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: employeeId === "new",
                        message: "Påkravet Feltet"
                      }
                    ]
                  })(
                    <Input
                      autoComplete="new-password"
                      prefix={<Icon type="lock" />}
                      type="password"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Telefon nr.">
                  {getFieldDecorator("phone")(
                    <Input placeholder="Telefon nr." />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <div className="form-group-title">Arbejdssted/Leder</div>
            <DepartmentsCheckboxTable
              companyLeaderIds={companyLeaderIds}
              companyIds={companyIds}
              handleLeaderCheckbox={this.handleLeaderCheckbox}
              handleCompanyCheckbox={this.handleCompanyCheckbox}
            />
          </Form>
        </Card>
      </>
    )
  }
}
export default Form.create()(EmployeeForm)

import http from "./httpService"
import { oneLoungeApi } from "../config.json"

const apiEndpoint = `${oneLoungeApi}/ticketOrder`

export const orderListUrl = `${apiEndpoint}/list`

export function orderUrl(id) {
  return `${apiEndpoint}/${id}`
}

export function getOrders() {
  return http.get(`${apiEndpoint}/List`)
}

export function getOrder(orderId) {
  return http.get(orderUrl(orderId))
}

export function sendMessage(message) {
  return http.post(`${apiEndpoint}/addMessage`, message)
}

export function saveOrder(order) {
  if (order.id !== 0) return http.put(apiEndpoint, order)

  return http.post(`${apiEndpoint}/setStatus/${order.id}`, order)
}

export function deleteOrder(orderId) {
  return http.delete(orderUrl(orderId))
}

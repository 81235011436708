import React, { useState, createContext, useContext } from "react"
import { getCurrentUser } from "../services/authService"

const UserContext = createContext()
const UserActionsContext = createContext()

function UserProvider({ children }) {
  const [user, setUser] = useState(getCurrentUser())

  return (
    <UserContext.Provider value={user}>
      <UserActionsContext.Provider value={setUser}>
        {children}
      </UserActionsContext.Provider>
    </UserContext.Provider>
  )
}

function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider")
  }
  return context
}

function useUserActions() {
  const context = useContext(UserActionsContext)
  if (context === undefined) {
    throw new Error("useUserActions must be used within a UserProvider")
  }
  return context
}
export { UserProvider, useUserActions, useUser }

import { message } from "antd"

export function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

export function reassignSort(list) {
  return list.map((obj, index) => Object.assign(obj, { sort: index }))
}
export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function beforeUploadCheck(file) {
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must be smaller than 2MB!")
  }
  return isLt2M
}
export function dummyRequest({ onSuccess }) {
  setTimeout(() => {
    onSuccess("ok")
  }, 0)
}

export function isFileImage(file) {
  return file && file.type.split("/")[0] === "image"
}
